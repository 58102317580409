import React, {ReactElement, useContext, useState} from 'react';
import {faArrowProgress, faDiagramProject, faExpand, faGear, faUser} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CommonContext} from 'components/common/CommonProvider';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import GlobalSettingsModal from 'components/common/GlobalSettingsModal';
import styled from 'styled-components';
import {DataContext, defaultGlobalSettingsValue} from 'api/DataProvider';
import useMetaPfdCommand from 'components/menu/pulldown/useMetaPfdCommand';
import Tooltip from 'components/common/Tooltip';
import UserProfileModal from 'components/auth/UserProfileModal';
import {MetaPfdContext} from 'components/mpfd';
import ProcessCanvas from 'pages/ProcessCanvas';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

const Container = styled.div`
  width: 100%;
  margin-top: auto;
  color: #86a5a9;
  text-align: center;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  li {
    margin: auto;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      color: #86a5a9;
    }

    &.global-setting {
      svg {
        cursor: pointer;
      }
    }

    &.user {
      background-color: rgba(109, 134, 196, 0.3);
      cursor: pointer;
    }
    &.apps {
      a {
        color: darkorange;
      }
    }
    &.full-screen {
      svg {
        cursor: pointer;
      }
    }
  }
`;

function UtilMenu(): ReactElement {
  const commands = useMetaPfdCommand();
  const [isShowUserProfile, setIsShowUserProfile] = useState(false);
  const {globalSettingsModalState} = useContext(DataContext);
  const [globalSettingElement, setIsShowSetting] = globalSettingsModalState;
  const location = useLocation();
  const {file: mPfdFile} = useContext(MetaPfdContext);
  const {file: pcFile} = useContext(ProcessCanvasContext);

  const onClickFullScreen = () => {
    commands.fullScreen();
  };

  const onClickUser = (): void => {
    setIsShowUserProfile(true);
  };

  const onClickSettings = (): void => {
    // showModal({content: 'Global Setting is under construction', onConfirm() {}});
    setIsShowSetting(true);
  };

  const onCloseSetting = (): void => {
    //
    setIsShowSetting(false);
  };

  const onCloseUserProfile = (): void => {
    setIsShowUserProfile(false);
  };

  return (
    <>
      <Container>
        <li className="apps">
          {location.pathname.startsWith('/pc') && (
            <Link to={`/mpfd${mPfdFile?._id ? '/' + mPfdFile?._id : ''}`}>
              <Tooltip content="Go to Meta PFD Editor">
                <FontAwesomeIcon icon={faArrowProgress} size="lg" />
              </Tooltip>
            </Link>
          )}
          {location.pathname.startsWith('/mpfd') && (
            <Link to={`/pc${pcFile?._id ? '/' + pcFile?._id : ''}`}>
              <Tooltip content="Go to Process Canvas">
                <FontAwesomeIcon icon={faDiagramProject} size="lg" />
              </Tooltip>
            </Link>
          )}
        </li>
        <li className="full-screen">
          <Tooltip content="Full Screen">
            <FontAwesomeIcon icon={faExpand} size="lg" onClick={onClickFullScreen} />
          </Tooltip>
        </li>
        <li className="global-setting">
          <Tooltip content="Global Settings">
            <FontAwesomeIcon icon={faGear} size="lg" onClick={onClickSettings} />
          </Tooltip>
        </li>
        <li className="user" onClick={onClickUser}>
          <Tooltip content="User Profile">
            <FontAwesomeIcon icon={faUser} size="lg" />
          </Tooltip>
        </li>
      </Container>
      {globalSettingElement && (
        <GlobalSettingsModal defaultValues={defaultGlobalSettingsValue} onClose={onCloseSetting} />
      )}
      {isShowUserProfile && <UserProfileModal onClose={onCloseUserProfile} />}
    </>
  );
}

export default UtilMenu;
