import {EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow, useStore} from 'reactflow';
import React, {useCallback, useContext} from 'react';
import {getEdgeParams, getXButtonParams} from 'components/pc/widgets/parts/floating-edge/utils/edge-functions';
import styled from 'styled-components';
import {checkDatasheetLoss} from 'components/pc/widgets/parts/utils/checkDatasheetLoss';
import {LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

const EdgeButton = styled.button`
  width: 20px;
  height: 20px;
  background: #fff3b8;
  border: 2px solid #00616e;
  color: #00616e;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bolder;
  line-height: 1em;
  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
`;

function SimpleFloatingEdge({id, source, target, markerEnd, style}: EdgeProps) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const {setEdges, getNodes} = useReactFlow();
  const {afterEdgesChange} = useContext(LocalDatabaseContext);
  const {showModal, hideModal} = useContext(CommonContext);
  const {isLineEditingModeState} = useContext(ProcessCanvasContext);
  const [isLineEditingMode] = isLineEditingModeState;

  if (!sourceNode || !targetNode) {
    return null;
  }
  const {sx, sy, tx, ty, sourcePos, targetPos} = getEdgeParams(sourceNode, targetNode);
  const [edgePath] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty
  });
  const [bx, by] = getXButtonParams(sourcePos, sx, sy);

  const onEdgeUpdate = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    setEdges(function (prevEdges) {
      const newEdges = prevEdges.filter((edge) => edge.id !== id);
      afterEdgesChange(getNodes(), newEdges);
      return newEdges;
    });
  };
  const onEdgeClick = () => {
    if (checkDatasheetLoss(sourceNode, targetNode)) {
      showModal({
        title: 'Notice',
        content: 'Data references in the datasheet will be lost. Do you want to disconnect?',
        confirmLabel: 'Disconnect',
        cancelLabel: 'Cancel',
        isShowCancel: true,
        onConfirm: onEdgeUpdate,
        onClose() {
          hideModal();
        }
      });
    } else {
      onEdgeUpdate();
    }
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={5}
        markerEnd={markerEnd}
        style={style}
      />
      {isLineEditingMode && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-10%, -140%) translate(${bx}px,${by}px)`,
              fontSize: 12,
              pointerEvents: 'all',
              zIndex: style?.zIndex as number
            }}
            className="nodrag nopan"
          >
            <EdgeButton onClick={onEdgeClick}>×</EdgeButton>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}
export default SimpleFloatingEdge;
