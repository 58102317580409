import {Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {MessageSpinner, ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import ServerItemList from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerItemList';
import {
  IModelRunnerProject,
  IModelRunnerServer,
  IReceiveMessageMacroFinishedData,
  IReceiveMessageRequestProjectConnectData,
  IReceiveMessageRequestProjectCreateData,
  IReceiveMessageRequestProjectInfoData,
  IReceiveMessageRequestProjectOpenData
} from 'components/pc/widgets/modelRunner/types';
import ServerInfo from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerInfo';
import {Button} from 'components/forms';
import PrevButton from 'components/mpfd/panel/modal/PrevButton';
import ConnectedProjectMessage from 'components/pc/widgets/modelRunner/initialize-modal-parts/ConnectedProjectMessage';
import ProjectItemList from 'components/pc/widgets/modelRunner/initialize-modal-parts/ProjectItemList';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import DisconnectModal from 'components/pc/widgets/modelRunner/initialize-modal-parts/DisconnectModal';
import {getProjectFileName, IModelRunnerLoader} from 'components/pc/widgets/modelRunner/useModelRunnerLoader';
import CountdownTimer from 'components/common/CountdownTimer';
import {IModelRunnerWidgetData} from 'components/pc/types';
import splashImage from 'assets/images/model-runner-splash.svg';

const splashBackgroundColor = '#e7f7ff';
const ElementContainer = styled.div``;
const SplashElementContainer = styled.div`
  width: 100%;
  height: 401px;
  padding: 40px;
  margin: -11px -20px -52px -20px;
  border-radius: 0 0 5px 5px;
  background-color: ${splashBackgroundColor};
  display: flex;
  overflow: hidden;
  justify-content: space-between;

  &.fit {
    margin: 0;
  }
`;
const ModelRunnerTitle = styled.div`
  font-size: 18px;
  height: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;

  h2 {
    font-weight: normal;
    margin-bottom: 8px;
  }
  small {
  }
`;
const SplashImage = styled.img`
  height: 80%;
`;
const ValidateMessage = styled.div`
  width: 100%;
  text-align: center;

  > em {
    font-style: normal;
    color: ${({theme}) => theme.color.invalid};
  }
`;

export type INextStep = {
  server?: IModelRunnerServer;
  project?: IModelRunnerProject;
  type?: 'server' | 'user';
  remove?: boolean;
};

type IProps = {
  loader: IModelRunnerLoader;
  metaData: IModelRunnerWidgetData;
  initializeModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  serverConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  projectConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  disconnectFromServer(): void;
};

function ModelRunnerInitializeModal({
  loader,
  metaData,
  initializeModalState,
  disconnectFromServer
}: IProps): ReactElement {
  const stepState = useState<number>(0);
  const [step, setStep] = stepState;

  const [isShowInitializeModal, setIsShowInitializeModal] = initializeModalState;

  const [isShowDisconnectModal, setIsShowDisconnectModal] = useState(false);
  const [isConnectingProject, setIsConnectingProject] = useState(false);
  const [isConnectingServer, setIsConnectingServer] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(undefined);
  const [modalTitle, setModalTitle] = useState('Version ' + process.env.REACT_APP_MODEL_MANAGER_VERSION); // = step < 3 ? 'Connect Model Manager Server' : 'Connect Model Manager Project';

  const [disabled, setDisabled] = useState<boolean>(true);

  const limitLicense = loader.projectList?.filter((project) => project.isRunning)?.length > 2;

  const connectedServerRef = useRef(false);

  useModalKeyBoardEvent({
    onConfirm: () => !disabled && goNext()
  });

  useEffect(() => {
    if (loader.error) {
      setIsConnectingServer(false);
    }
  }, [loader.error]);

  // custom server select 시 보내는 정보가 비어있는지 확인 후 disabled 처리
  useEffect(() => {
    if (loader.server === undefined) return;
    else if (loader.server.custom) {
      const isDisabled = Object.entries(loader.server).some(([, value]) => value === null || value === '');
      setDisabled(isDisabled);
    } else {
      setDisabled(false);
    }
  }, [loader.server]);

  const showConfirmBtn = step === 2 || (step === 4 && loader?.isValidProject);

  const confirmBtnTitle = step === 2 ? 'Connect' : 'Ok';

  const onConfirmDisconnect = () => {
    disconnectFromServer();
    loader.resetServer();
    loader.resetProject();
    setIsShowDisconnectModal(false);
    setStep(1);
  };

  useEffect(() => {
    // widget 에 저장된 서버가 있는 경우 연결
    if (metaData?.server) {
      loader.connectToServer(metaData.server);
      setLoadingMessage('Connecting server.\nPlease wait a moment.');
      setIsConnectingServer(true);
    }
  }, []);

  // step 이 변함에 따라 자동으로  동작하는 추가 step
  useEffect(() => {
    // console.log('step = ', step, loader.server, loader.project);

    if (!isShowInitializeModal) return;

    const {Cmd, Data} = loader?.socketResponse ?? {};

    const {
      isConnected,
      server,
      project,
      isValidProject,
      getServerList,
      requestProjectList,
      requestProjectInfo,
      requestProjectConnect,
      requestProjectOpen,
      updateProject
    } = loader;

    // step = 0 은 최초 상태라 클릭 이벤트 처리가 없음
    if (step === 0) {
      // metaData 에 저장된 server 존재여부 분기
      if (!server) {
        // 서버 목록을 가져옴
        getServerList().then(() => setTimeout(() => setStep(1), 1500));
        // 현재 실행되지 않는 코드로 추정
        /*  if (isConnected) {
          // ioInfo 받지 않는 옵션으로 프로젝트 정보 요청
          if (project) {
            // requestProjectInfo(project.fileName, true, true, false);
          } else {
            requestProjectList(server);
          }
        }*/
      }
    } else if (step === 1) {
      setModalTitle('Connect Model Manager Server');

      if (!isConnected) return;

      // 무조건 server 에 연결 되어 있어야 다음 동작이 가능

      if (connectedServerRef.current === false) {
        if (loader.project) {
          const found = loader.projectList.find((project) => project.fileName === loader.project.fileName);
          // 저장된 project를 stanby 동작 후 저장하지 않았을 때 project connect 하는 조건
          if (found && found.isRunning && !found.pmvUser) {
            // setIsConnectingServer(true);
            connectedServerRef.current = true;
            requestProjectConnect(loader.project.fileName);
            // 저장된 project를 shutdown 동작 후 저장하지 않았을 때 project open 하는 조건
          } else if (found && !found.isRunning) {
            requestProjectOpen(loader.project.fileName);
          }
        } else {
          // 신규 model runner widget 인 경우 이 case
          if (server) requestProjectList(server);
        }
      }

      // if (Cmd === 'ReqProjectConnect') {
      //   // 저장된 widget 이라 project 가 이미 있기 때문에 'ioInfo 받지 않는 옵션'으로 프로젝트 정보 요청
      //   requestProjectInfo(loader.project.fileName, true, true, false);
      // }
      if (Cmd === 'ReqProjectList') {
        setIsConnectingServer(false);
        setStep(3);
        if (metaData?.project) {
          setLoadingMessage('Connecting project.\nPlease wait a moment.');
          setIsConnectingProject(true);
        }
      }
      if (isValidProject) {
        setStep(4);
      }
    } else if (step === 2) {
      setModalTitle('Connect Model Manager Server');
      if (isConnected) {
        if (server) requestProjectList(server);
        // connectToServer 후에 소켓이 즉시 사용 가능하지 않아, 잠시 지연시킨후에 요청 전송
        // requestProjectInfo(project.fileName, true, true, false);
        if (Cmd === 'ReqProjectList') {
          // console.log('여기>>> 1');
          // setIsConnectingServer(false);
          setStep(3);
        }
      }
    } else if (step === 3) {
      setModalTitle('Open Model Manager Project');
      if (Cmd === 'ReqProjectList') {
        setIsConnectingServer(false);
      } else if (Cmd === 'ReqProjectCreate' || Cmd === 'ReqProjectOpen') {
        const data = Data as IReceiveMessageRequestProjectCreateData | IReceiveMessageRequestProjectOpenData;
        if (data?.Notify?.ErrorCode === 0) {
          setLoadingMessage('Connecting project.\nPlease wait a moment.');
          setIsConnectingProject(true);
        }
      } else if (Cmd === 'ReqProjectConnect') {
        // open 직후 호출할 경우 생기는 invalid project error 방지 setTimeout 코드
        const data = Data as IReceiveMessageRequestProjectConnectData | IReceiveMessageRequestProjectOpenData;
        setTimeout(() => {
          // console.log('loader?.project = ', loader?.project);
          const isIoLoad = metaData?.project?.ioInfo === undefined || metaData?.project?.ioInfo?.length === 0;
          requestProjectInfo(data.ProjectName, true, true, isIoLoad);
          // setStep(4);
        }, 1000);
      } else if (Cmd === 'ReqProjectInfo') {
        const data = Data as IReceiveMessageRequestProjectInfoData;
        if (data?.Notify?.ErrorCode === 0) {
          setStep(4);
        }
      } else if (Cmd === 'ReqProjectRemove') {
        setIsConnectingProject(false);
        requestProjectList(server);
      }
    } else if (step === 4) {
      setIsConnectingProject(false);
    }
  }, [
    isShowInitializeModal,
    step,
    loader.server,
    loader.project,
    loader.isConnected,
    loader.isValidProject,
    loader.socketResponse,
    loader.projectList
  ]);

  // MacroFinished 분리
  useEffect(() => {
    if (!isShowInitializeModal) return;
    const {Cmd, Data} = loader?.socketResponse ?? {};
    const {requestProjectInfo, updateProject, clearSocketResponse} = loader;

    if (Cmd === 'MacroFinished') {
      const data = Data as IReceiveMessageMacroFinishedData;
      const alias = getProjectFileName(data.ProjectName);
      updateProject({alias});
      console.log('metaData = ', metaData);
      const isIoLoad = metaData?.project?.ioInfo === undefined || metaData?.project?.ioInfo?.length === 0;
      requestProjectInfo(data.ProjectName, true, true, isIoLoad);
      setIsConnectingProject(false);
      clearSocketResponse();
    }
  }, [loader.socketResponse]);

  /**
   * 사용자의 선택에 따라 다음 단계로 진행
   * @param params
   */
  const goNext = (params?: INextStep): void => {
    const {connectToServer, updateServer} = loader;
    const {server} = params ?? {};

    if (step === 1 && server) {
      // custom server 가 아닐 경우 값을 false 로 변경
      if (!Object.keys(server).includes('custom')) {
        server.custom = false;
        setIsConnectingServer(true);
      }
      updateServer(server);
      if (server.custom) {
        setIsConnectingServer(false);
        setStep(2);
      } else {
        // setIsConnectingServer(true);
        setLoadingMessage('Connecting server.\nPlease wait a moment.');
        connectToServer(server);
      }
    } else if (step === 2) {
      setIsConnectingServer(true);
      setLoadingMessage('Connecting server.\nPlease wait a moment.');
      connectToServer(loader.server);
    } else if (step === 4) {
      setIsShowInitializeModal(false);
    }
  };

  /**
   * 사용자가 선택에 따라 이전 단계로 진행
   */
  const goPrev = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setIsShowDisconnectModal(true);
    }
  };

  /**
   * step 5 에서
   * @param params
   */
  const onSelectProject = (params: INextStep) => {
    const {createProject, updateProject, requestProjectConnect, requestProjectOpen, requestProjectRemove} = loader;
    const {project, type, remove} = params ?? {};
    if (type === 'server') {
      // user project list 에서 server 목록의 create 버튼 클릭 이벤트
      const parts = project.fileName.split('\\');
      parts.splice(-2, 2);
      parts.push('UserProject', project.projectName, project.projectName + '.mms');
      const fileName = parts.join('\\');
      createProject(project.fileName, project.projectName);
      updateProject({...project, fileName});
      setIsConnectingProject(true);
      setLoadingMessage('Connecting project.\nPlease wait a moment.');
    } else if (type === 'user') {
      // ReqProjectOpen 해야 할 상황
      if (!params.project.isRunning) {
        if (remove) {
          setIsConnectingProject(true);
          setLoadingMessage('The project is being removed.\nPlease wait a moment.');
          requestProjectRemove(project.fileName, '');
        } else {
          // 라이센스 3개 이상 제한
          if (!limitLicense) {
            setIsConnectingProject(true);
            setLoadingMessage('Connecting project.\nPlease wait a moment.');
            requestProjectOpen(project.fileName);
            updateProject({...project});
          }
        }
      } else {
        // ReqProjectConnect 만 해도 될 상황
        if (!params.project.pmvUser) {
          setIsConnectingProject(true);
          setLoadingMessage('Connecting project.\nPlease wait a moment.');
          requestProjectConnect(project.fileName);
          updateProject({...project});
        }
      }
      // user project list 에서 user 목록의 open 버튼 클릭 이벤트
      // requestProjectInfo(project.fileName);
    }
  };

  return (
    <ModalWrapper type="inner" style={{backgroundColor: step === 0 ? splashBackgroundColor : 'white'}}>
      <ModalHeader style={{borderBottomColor: step === 0 && splashBackgroundColor}}>
        {modalTitle}
        {/*<span style={{color: '#eee'}}> Step {step} / 4</span>*/}
      </ModalHeader>
      <ModalBody align="center" size="full-width" height={400}>
        <StepContainer width={700} height={400}>
          <StepScroller total={5} step={step}>
            <Step>
              <SplashElementContainer className={step > 0 && 'fit'}>
                <ModelRunnerTitle>
                  <h2>Model Runner</h2>
                  <small>with ProcessMetaverse™ Model Manager</small>
                </ModelRunnerTitle>
                <SplashImage src={splashImage} alt="Model Runner" />
              </SplashElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <ServerItemList serverList={loader.serverList} onSelect={goNext} />
              </ElementContainer>
            </Step>
            <Step>
              <PrevButton onClick={goPrev} />
              <ElementContainer>
                <ServerInfo loader={loader} />
              </ElementContainer>
            </Step>
            <Step>
              <PrevButton onClick={goPrev} />
              <ElementContainer>
                <ProjectItemList disabled={limitLicense} projectList={loader?.projectList} onSelect={onSelectProject} />
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <ConnectedProjectMessage isValidProject={loader.isValidProject} project={loader?.project} />
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
      <ModalFooter>
        {step === 3 && limitLicense && (
          <ValidateMessage>
            <em>The license has been exceeded.</em> You can open a disconnected project.
          </ValidateMessage>
        )}
        {step === 4 && loader.isValidProject && (
          <CountdownTimer start={step === 4} total={2} onComplete={() => setIsShowInitializeModal(false)} />
        )}
        {showConfirmBtn && (
          <Button disabled={disabled} width={120} onClick={() => goNext()}>
            {confirmBtnTitle}
          </Button>
        )}
        {isShowDisconnectModal && (
          <DisconnectModal onClose={() => setIsShowDisconnectModal(false)} onConfirm={onConfirmDisconnect} />
        )}
        <MessageSpinner
          isShow={isConnectingProject}
          type="overlay"
          delayTime={0.7}
          // onClose={() => setIsConnectingServer(false)}
        >
          {loadingMessage}
        </MessageSpinner>
        <MessageSpinner
          isShow={isConnectingServer}
          type="overlay"
          delayTime={0.7}
          // onClose={() => setIsConnectingServer(false)}
        >
          {loadingMessage}
        </MessageSpinner>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default ModelRunnerInitializeModal;
