export type EdgeType = 'both' | 'source' | 'target' | 'none';

/**
 * edge 연결 가능 상태 반환
 * both : import, export 모두 가능한 상태 / target : import 만 가능한 상태 / source : export 만 가능한 상태 / none : import, export 모두 불가능한 상태
 * @param limitIn
 * @param limitOut
 * @param edgeIn
 * @param edgeOut
 */
export const getEdgeInfo = (limitIn: number, limitOut: number, edgeIn: number, edgeOut: number): EdgeType => {
  if (edgeIn < limitIn && edgeOut < limitOut) {
    return 'both';
  }

  if (edgeIn < limitIn && edgeOut === limitOut) {
    return 'target';
  }

  if (edgeIn === limitIn && edgeOut < limitOut) {
    return 'source';
  }

  if (edgeIn >= limitIn && edgeOut >= limitOut) {
    return 'none';
  }
  return 'both';
};
