import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRepeat} from '@fortawesome/sharp-light-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import GroupDivider from 'components/forms/GroupDivider';
import {faWindowRestore} from '@fortawesome/pro-light-svg-icons';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import {ISize} from 'components/common/types';
import TimeSelector, {
  defaultIntervalUnitList,
  getMillisecondsByUnit,
  getUnitByMilliseconds,
  ITimeSelector,
  TimeUnitTypes
} from 'components/forms/TimeSelector';
import {IDatasheetLocalDbWidgetData} from 'components/pc/types';
import NumberStepper from 'components/forms/NumberStepper';
import {IPythonEditorSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';

const FormWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  height: 38px;

  &.on {
    flex-direction: column;
    height: 100%;
    row-gap: 6px;
  }

  .common-select-form {
    height: 38px;
    padding: 0 10px;
  }

  .common-text-form {
    input {
      border-radius: 5px;
    }
  }

  svg {
    width: 26px;
    height: 26px;
    border-radius: 3px;
    padding: 5px;
    color: #888;
    opacity: 0.4;
    &:not(.disabled) {
      opacity: 1;
      &:hover {
        color: #1f1f1f;
        background-color: #d5e1f3;
        cursor: pointer;
      }
    }
    &.isRepeat {
      color: #1f1f1f;
      background-color: #d5e1f3;
    }
  }
`;

const DataWrap = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
`;

const TextFormWrap = styled.div`
  display: flex;
  height: 38px;
`;

const RowIndexForm = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;

  &.on {
    height: 35px;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #acbcc0;
  }

  input {
    width: 52px;
  }
`;

const BtnWrap = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
  margin-left: auto;
  display: flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: -2px;
  border-radius: 3px;
  padding: 5px;
  color: #888;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }

  &.selected {
    background-color: #d5e1f3;
    margin-right: 1px;
    svg {
      color: #1f1f1f;
    }
  }
`;

const RowWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  gap: 7px;
`;

type IProps = {
  widgetSize: ISize;
  iconMode: boolean;
  selectedRowIdxState: [number, Dispatch<SetStateAction<number>>];
  isRepeatState: [boolean, Dispatch<SetStateAction<boolean>>];
  intervalValueState: [number, Dispatch<SetStateAction<number>>];
  intervalUnitState: [TimeUnitTypes, Dispatch<SetStateAction<TimeUnitTypes>>];
  maxRow: number;
  startRowIdx: number;
  connectedPythonEditorSubjectInfo: IWidgetSubject<IPythonEditorSubject>;
  id: string;
  next(row?: number): Promise<boolean>;
  onClickIconModeIcon(): void;
  metaData: IDatasheetLocalDbWidgetData;
};

function DatasheetWidgetActionPanel({
  widgetSize,
  iconMode,
  selectedRowIdxState,
  isRepeatState,
  intervalValueState,
  intervalUnitState,
  maxRow,
  startRowIdx,
  connectedPythonEditorSubjectInfo,
  id,
  next,
  onClickIconModeIcon,
  metaData
}: IProps) {
  const [selectedRowIdx, setSelectedRowIdx] = selectedRowIdxState;
  const [intervalValue, setIntervalValue] = intervalValueState;
  const [intervalUnit, setIntervalUnit] = intervalUnitState;
  const [isRepeat, setIsRepeat] = isRepeatState;
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(connectedPythonEditorSubjectInfo?.ref?.current?.batchRun);
  }, [connectedPythonEditorSubjectInfo?.ref.current]);

  const iconModeToggle = iconMode ? 'on' : 'off';

  useEffect(() => {
    let timerId;
    if (intervalValue && intervalUnit && !disabled) {
      timerId = setInterval(
        () => next(Number(startRowIdx)),
        getMillisecondsByUnit({value: intervalValue, unit: intervalUnit})
      );
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [isRepeat, intervalUnit, intervalValue, maxRow, startRowIdx, selectedRowIdx, disabled]);

  const onChangeSelectIdx = (idx: number) => {
    let n = Number(idx);
    if (isNaN(n)) {
      n = null;
    }
    setSelectedRowIdx(n);
    metaData.selectedRowIdx = n;
  };

  const onClickAutoRunButton = () => {
    setIsRepeat((prev) => {
      metaData.isRepeat = !prev;
      return !prev;
    });
  };

  const onChangeAutoRunRange = (autoRunUnit: ITimeSelector) => {
    setIntervalUnit(autoRunUnit.unit);
    setIntervalValue(Number(autoRunUnit.value));
    metaData.intervalValue = Number(autoRunUnit.value);
    metaData.intervalUnit = autoRunUnit.unit;
  };

  // 수정 전 datasheet metadata 호환성 작업
  const getTimeSelectorUnit = (): TimeUnitTypes => {
    if (metaData?.autoRunConfig?.intervalUnit) {
      return getUnitByMilliseconds(metaData?.autoRunConfig?.intervalUnit);
    }
    if (metaData?.intervalUnit) {
      return metaData?.intervalUnit;
    }
    return intervalUnit;
  };
  return (
    <>
      <FormWrap className={classNames(iconModeToggle)}>
        <DataWrap className={classNames(iconModeToggle)}>
          <TimeSelector
            value={metaData?.autoRunConfig?.intervalValue || intervalValue}
            unit={getTimeSelectorUnit()}
            options={defaultIntervalUnitList}
            onChange={onChangeAutoRunRange}
            disabled={disabled}
          />
          <FontAwesomeIcon
            className={classnames({disabled}, isRepeat && 'isRepeat')}
            icon={faArrowsRepeat as IconProp}
            size="lg"
            onClick={onClickAutoRunButton}
          />
        </DataWrap>
        {!iconMode && <GroupDivider />}
        <RowIndexForm className={classNames(iconModeToggle)}>
          <RowWrap>
            {widgetSize?.width >= 610 && <div className="text-form-title">Selected row index :</div>}
            <NumberStepper
              value={selectedRowIdx}
              onChange={onChangeSelectIdx}
              min={Number(startRowIdx)}
              max={maxRow - 1}
              disabled={disabled}
              loop
            />
          </RowWrap>
          <Tooltip content={!iconMode ? 'Icon Mode On' : 'Icon Mode Off'} place="bottom">
            <BtnWrap
              className={classnames(iconMode && 'selected', iconModeToggle)}
              onClick={() => onClickIconModeIcon()}
            >
              <FontAwesomeIcon icon={faWindowRestore} size="lg" onClick={() => {}} />
            </BtnWrap>
          </Tooltip>
        </RowIndexForm>
      </FormWrap>
    </>
  );
}

export default DatasheetWidgetActionPanel;
