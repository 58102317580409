import React, {ChangeEvent, ReactElement, useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {FormRow} from 'components/forms';
import {ColorPicker} from 'components/table/parts';
import Button from 'components/forms/Button';
import ModalFooter from 'components/common/modal/ModalFooter';
import {objectsAreEqual} from 'utils/object-utils';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export type ITimeSeriesConfigs = {
  chartBackground: string;
  chartTextColor: string;
};

type IProps = {
  configs: ITimeSeriesConfigs;
  onConfirm(configs: ITimeSeriesConfigs, action?: 'updateTimer'): void;
  onCancel(): void;
  defaultValues: ITimeSeriesConfigs;
};

function TimeSeriesConfigs({configs, onConfirm, onCancel, defaultValues}: IProps): ReactElement {
  const [cfg, setCfg] = useState<ITimeSeriesConfigs>(configs);
  const {showModal, hideModal} = useContext(CommonContext);
  const disabledNoneButton = useMemo(() => objectsAreEqual<ITimeSeriesConfigs>(cfg, defaultValues), [cfg]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    console.log('name, value = ', name, value);
    setCfg({...cfg, [name]: value} as ITimeSeriesConfigs);
  };

  const onClickConfirm = (): void => {
    onConfirm(cfg);
  };

  const onRestore = () => {
    showModal({
      title: 'Restore Defaults',
      content: 'Do you want to restore default?',
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      isShowCancel: true,
      onConfirm: () => {
        setCfg(defaultValues);
      },
      onClose() {
        hideModal();
      }
    });
  };
  return (
    <Container>
      <FormRow header="Chart background color">
        <ColorPicker flattenKeys="chartBackground" color={cfg.chartBackground} width={30} onChange={onChange} />
      </FormRow>
      <FormRow header="Chart axis label text color">
        <ColorPicker flattenKeys="chartTextColor" color={cfg.chartTextColor} width={30} onChange={onChange} />
      </FormRow>
      <ModalFooter>
        <Button style={{marginRight: 'auto'}} variant="link" disabled={disabledNoneButton} onClick={onRestore}>
          Restore Defaults
        </Button>
        <Button width={100} onClick={onClickConfirm}>
          Confirm
        </Button>
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default TimeSeriesConfigs;
