import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {CSSProperties, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {IPythonEditorWidgetData, IWidgetNodeData} from 'components/pc/types';
import PythonEditorBodyTop from 'components/pc/widgets/pythonEditor/PythonEditorBodyTop';
import PythonEditorBodyBottom from 'components/pc/widgets/pythonEditor/PythonEditorBodyBottom';
import PythonCodeEditor from 'components/pc/widgets/pythonEditor/PythonCodeEditor';
import PythonCodeResult from 'components/pc/widgets/pythonEditor/PythonCodeResult';
import PythonEditorActionPanel from 'components/pc/widgets/pythonEditor/PythonEditorActionPannel';
import PythonEditorVariableTable from 'components/pc/widgets/pythonEditor/PythonEditorVariableTable';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {
  INodeSelectorTargetInfo,
  IPythonEditorKernelCodRunResult,
  IPythonEditorLog,
  IPythonEditorRunConfig,
  IPythonEditorSimulationResult,
  IPythonEditorVariable,
  IReturnPythonEditorCodeRun,
  IValueGroupObj,
  PythonLogActionType
} from 'components/pc/widgets/pythonEditor/types';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import useApi from 'api/useApi';
import {DatabaseHierarchy} from 'api/data-types';
import {IPythonEditorSubject, LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {pythonEditorDefaultStateValue, pythonEditorLogMsg} from 'components/pc/widgets/pythonEditor/constants';
import WidgetModal from 'components/spreadsheet/parts/WidgetModal';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import PythonEditorWidgetEnvironmentPackageList from 'components/pc/widgets/pythonEditor/PythonEditorWidgetEnvironmentPackageList';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {getUniqueKey} from 'utils/commons';
import dayjs from 'dayjs';
import WidgetIconModeHeader from 'components/pc/widgets/parts/WidgetIconModeHeader';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import styled from 'styled-components';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import {DataContext} from 'api/DataProvider';
import {MessageSpinner} from 'components/common';
import {getApiHost} from 'api/function';
import {LocalStorageManager} from 'utils/local-storage-manager';
import usePythonPos from 'components/pc/widgets/pythonEditor/usePythonPos';

const TopResizeArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
`;

export type IPythonEditorOutflowResult = {
  outflowResult: {
    [key: string]: string | number;
  };
};

function PythonEditorWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const variableTableState = useState<IPythonEditorVariable[]>([]);
  const runLogHistoryState = useState<IPythonEditorLog[]>([]);
  const codeState = useState<string>(pythonEditorDefaultStateValue.codeState);
  const runConfigState = useState<IPythonEditorRunConfig>(pythonEditorDefaultStateValue.runConfigState);
  const nodeSelectorTargetInfoState = useState<INodeSelectorTargetInfo>(null);
  const codRunResultState = useState<IPythonEditorKernelCodRunResult>(null);
  const {availableDatabaseHierarchyList} = useContext(DataContext);
  const [variableTable, setVariableTable] = variableTableState;
  //const [pythonDynamicHierarchy, setPythonDynamicHierarchy] = useState<DatabaseHierarchy>();
  const [isReady, setIsReady] = useState(false);
  const [kernelId, setKernelId] = useState<string>('');

  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1, duration: 5000});
  const api = useApi();
  const reactFlow = useReactFlow();
  const {
    boundaryTopRef,
    editorBodyTopPosition,
    boundaryBottomRef,
    dragHandleTopRef,
    onMouseDownTopResizeHandle,
    widgetBottomHeight,
    editorBodyBottomPosition,
    dragHandleBottomRef,
    onMouseDownBottomResizeHandle
  } = usePythonPos(data);

  const {updateSubjectInfo} = useContext(LocalDatabaseContext);
  const {localDatabase, sourceDatabase, dynamicHierarchy} = useLocalDatabase(id);

  const getUpdateVariableTable = (isConnected: boolean, keys: string[]) => {
    if (keys.length > 0) {
      return variableTable.map((row) => {
        if (!keys.includes(row.path[0]) && row.path.length > 0) {
          return {...row, path: isConnected ? [localDatabase.name, row.path[1]] : []};
        }
        return row;
      });
    }
    return variableTable;
  };

  const [pythonEditorWidgetLogHistory, setPythonEditorWidgetLogHistory] = runLogHistoryState;
  const [pythonRunOutflowResult, setPythonRunOutflowResult] = useState<IPythonEditorOutflowResult>();

  const [isShowWidgetModal, setIsShowWidgetModal] = useState(false);
  const isShowEnvironmentPackageListModalState = useState(false);
  const [isShowEnvironmentPackageListModal, setIsShowEnvironmentPackageListModal] =
    isShowEnvironmentPackageListModalState;
  const [queueResetTimeoutId, setQueueResetTimeoutId] = useState<NodeJS.Timeout>(null);

  const queueResetTimeoutIdRef = useRef<any>(null);
  const isRunState = useState<boolean>(false);
  const [isRun, setIsRun] = isRunState;
  const isRunRef = useRef<boolean>(null);
  const [stopRequest, setSopRequest] = useState<any>(null);
  const stopRequestRef = useRef<any>(null);

  const clearLogHistory = () => {
    setPythonEditorWidgetLogHistory([]);
  };

  const [pythonEditorLogWidgetId, setPythonEditorLogWidgetId] = useState<string>(null);

  const afterRemovePythonLogWidget = () => {
    setPythonEditorLogWidgetId(null);
  };

  const [pythonEditorSimulationResultHistory, setPythonEditorSimultationResultHistory] = useState<
    IPythonEditorSimulationResult[]
  >([]);
  const [pythonEditorSimulationResultWidgetId, setPythonEditorSimulationResultWidgetId] = useState<string>(null);

  const afterRemovePythonSimulationWidget = () => {
    setPythonEditorSimulationResultWidgetId(null);
  };

  const clearResultHistory = () => {
    setPythonEditorSimultationResultHistory([]);
  };

  const iconModeState = useState<CSSProperties>(null);
  const [iconMode, setIconMode] = iconModeState;

  const subjectRef = useRef<IPythonEditorSubject>(null);

  const focusedRowColumnData = () => {
    const data = localDatabase?.ref?.current?.dbData;
    const focusedColumnIdx = localDatabase?.ref?.current.focusedColumnIdx || 0;
    const result = {};
    if (!data) return result;
    let headersRowIndex = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].some((cell) => cell !== undefined && cell !== null && cell !== '')) {
        headersRowIndex = i;
        break;
      }
    }
    if (headersRowIndex === -1) return result;

    const headers = data[headersRowIndex];
    const rows = data?.slice(1); // header row slice

    const idx = localDatabase?.ref?.current?.focusedRowIdx - 1;
    if (idx < 0) {
      return result;
    }
    const row = rows?.[localDatabase?.ref?.current?.focusedRowIdx - 1];
    if (!row) {
      return result;
    }

    for (let i = focusedColumnIdx; i < headers.length; i++) {
      const tag = headers[i];
      if (!tag) continue;
      result[tag] = row[i] !== undefined ? row[i] : null;
    }

    return result;
  };

  const valueFromDatasheet = focusedRowColumnData();
  const [code, setCode] = codeState;
  const [runConfig, setRunCfg] = runConfigState;
  const [codeRunResult, setCodeRunResult] = codRunResultState;
  const [nodeSelectorTargetInfo, setNodeSelectorTargetInfo] = nodeSelectorTargetInfoState;
  const databaseValueState = useState<IValueGroupObj>({});
  const [databaseValue, setDatabaseValue] = databaseValueState;
  const databaseValueRef = useRef(databaseValue);

  const runCode = async () => {
    const start = Date.now();
    if (!kernelId) return;
    /**
     * queue 초기화 타이머가 돌고있다면 reset
     * Python code run result 가 Null 이 아닌 상태에서 새로운 result sheet 연결했을때 기존의 garbage 값이 보이는 것 방지
     */
    if (queueResetTimeoutIdRef.current) {
      clearTimeout(queueResetTimeoutIdRef.current);
      setQueueResetTimeoutId(null);
    }

    const inflowForLog = {};
    const variablesCode = await Promise.all(
      variableTable.map(async (row) => {
        if (row.flowType === 'Inflow') {
          const searchResult = databaseValueRef?.current?.[row.keys]?.value?.[0]?.[1];
          const valueFromDatasheet = focusedRowColumnData();

          const searchResultFormDatasheet = valueFromDatasheet?.[row?.path?.[1]];
          if (searchResult) {
            inflowForLog[row?.variableName] = searchResult;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResult || '')}`;
          } else if (searchResultFormDatasheet) {
            inflowForLog[row?.variableName] = searchResultFormDatasheet;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResultFormDatasheet || '')}`;
          }
        }
      })
    );
    const outflowRequest = variableTable
      .filter(({flowType}) => flowType === 'Outflow')
      .map(({variableName}) => variableName);

    const [pipLines, codeLines] = code
      .split('\n')
      .reduce(
        ([pip, rest], line) =>
          line?.startsWith('pip install')
            ? [[...pip, line?.replace('pip install ', '')], rest]
            : [pip, [...rest, line]],
        [[], []]
      );
    const idx = localDatabase?.ref?.current?.focusedRowIdx - 1 || 0;
    const fullCode = `
${variablesCode.join('\n')}
SELECTED_ROW_INDEX = ${idx}
${codeLines.join('\n')}
    `;

    addPythonLog('start', '', {inflow: inflowForLog});

    const response = await api.post<IReturnPythonEditorCodeRun>('/python_editor/run_kernel_code', {
      kernel_id: kernelId,
      requirements: pipLines,
      code: fullCode,
      export_list: outflowRequest
    });
    /**
     * Click Pause Btn
     */
    if (!isRunRef.current) {
      return;
    }

    const end = Date.now();
    const diff = end - start;
    const diffTime = dayjs(diff).format(diff > 6000 ? 'm [min] s [sec]' : 's [sec]');
    const msg_list = response?.data?.msg;

    if (response.success) {
      setCodeRunResult(response.data);

      if (msg_list?.find((item) => item?.type === 'error')) {
        setIsShowWidgetModal(true);
        setIsRun(false);
        addPythonLog('end', diffTime, {});
        return;
      }
    } else {
      setIsRun(false);
      setIsShowWidgetModal(true);
      addPythonLog('end', diffTime, {});
      return;
    }

    /**
     * pip install 결과가 있으면 package list 갱신
     */
    if (msg_list?.find((item) => item?.type === 'install')) {
      // getPythonEnvironmentPackageList();
    }

    const outflowRes = response?.data?.variable_list || {};
    let outflowResult = {};
    Object.entries(outflowRes).forEach(([key, value]) => {
      if (typeof value === 'number' || typeof value === 'string') {
        outflowResult = {...outflowResult, [key]: value};
      }
    });

    sourceDatabase?.ref?.current?.getOutflowResult({outflowResult});

    variableTable.forEach((variable) => {
      if (variable.flowType === 'Outflow' && outflowResult[variable.variableName] !== undefined) {
        variable.variableValue = outflowResult[variable.variableName];
      }
    });

    const node_data_list = variableTable
      .filter((variable) => variable.variableDbType === 'cloud')
      .filter((variable) => variable.flowType === 'Outflow')
      .map((variable) => {
        const [db, ...path] = variable.path;
        if (!outflowResult?.[variable?.variableName]) {
          return null;
        }
        return {
          database: db,
          path: path,
          records: [[Date.now() / 1000, outflowResult?.[variable?.variableName]]]
        };
      });

    const node_data_list_r = node_data_list?.filter((item) => item);
    if (node_data_list_r.length > 0) {
      await api.post('/db_manage/store_node_data_list', {node_data_list: node_data_list_r});
    }

    if (runConfig?.autoRun) {
      if (runConfig?.intervalValue > 0) {
        setTimeout(() => runCode(), runConfig?.intervalValue * runConfig?.intervalUnit);
      }
    } else {
      if (runConfig?.batchRun) {
        localDatabase?.ref?.current?.next().then((success) => {
          if (success) {
            setTimeout(() => runCode(), 100);
          } else {
            setIsRun(false);
          }
        });
      } else {
        setIsRun(false);
      }
    }
    addPythonLog('end', diffTime, {outflow: outflowResult});
    setPythonEditorSimultationResultHistory(
      (prev) =>
        [
          ...prev,
          {timestamp: end, variableInfo: {outflow: outflowResult, inflow: inflowForLog}}
        ] as IPythonEditorSimulationResult[]
    );
    reserveClearResultQueue();
    return response;
  };

  const reserveClearResultQueue = () => {
    const timer = setTimeout(() => setPythonRunOutflowResult(null), 5000);
    setQueueResetTimeoutId(timer);
  };

  const onSelectNodeSelector = (checked: string[]) => {
    const parsedData = checked.map((item) => JSON.parse(item));

    if (parsedData?.[0]) {
      const path = parsedData?.[0];
      let variableDbType = 'cloud';
      if (dynamicHierarchy?.database === path?.[0]) {
        variableDbType = 'local';
      }
      setVariableTable((prev) => {
        return prev.map((row) =>
          row.keys === nodeSelectorTargetInfo.key ? {...row, variableDbType, path: parsedData?.[0]} : row
        );
      });
    }
    setNodeSelectorTargetInfo(null);
  };

  const onSetNodeSelectorTargetInfo = (key: string, path: string[]) => {
    setNodeSelectorTargetInfo({key, path});
  };

  const onCloseNodeSelector = () => {
    setNodeSelectorTargetInfo(null);
  };

  const stopPythonCodeRunning = () => {
    const now = Date.now();
    addPythonLog('stop');
    setSopRequest(now);
  };

  const addPythonLog = (
    actionType: PythonLogActionType,
    detail?: string,
    variableInfo?: {[typeKye: string]: {[key: string]: string | number}}
  ) => {
    let msg = pythonEditorLogMsg[actionType];
    switch (actionType) {
      case 'end': {
        msg = msg + ': running time(' + detail + ')';
        break;
      }
      default:
      // nothing to do
    }
    const timestamp = Date.now();
    setPythonEditorWidgetLogHistory(function (prev) {
      return [...prev, {timestamp, msg, variableInfo}];
    });
  };

  const addPythonLogWidget = () => {
    /**
     * 이미 존재할때
     */
    if (pythonEditorLogWidgetId) {
      reactFlow.setNodes((nodes) => nodes.filter((item) => item.id !== pythonEditorLogWidgetId));
      afterRemovePythonLogWidget();
      return;
    }

    const logId = getUniqueKey();
    setPythonEditorLogWidgetId(logId);
    const {x, y} = reactFlow.getNode(id).position;
    const {width, height} = reactFlow.getNode(id).style;
    const position = {x: x + Number(width) + 20, y};
    reactFlow.addNodes({
      id: logId,
      type: 'PythonEditorLogWidget',
      data: {
        title: data.title,
        customizedTitle: false,
        metaData: {
          targetPythonEditorId: id
        },
        minWidth: 300,
        minHeight: 300
      },
      style: {
        minWidth: 300,
        minHeight: 300,
        width: 300,
        height
      },
      zIndex: rest.zIndex,
      position
    });
  };

  //pythonEditor widget title을 result, log widget의 title로 변경하는 코드
  const onChangeTitle = (title: string) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === pythonEditorSimulationResultWidgetId || node.id === pythonEditorLogWidgetId) {
          if (data.title.length === 0) {
            node.data.title = 'Untitled';
            node.data.customizedTitle = false;
          } else {
            node.data.title = title;
            node.data.customizedTitle = true;
          }
        }
        return node;
      })
    );
  };

  const addPythonSimulationWidget = () => {
    /**
     * 이미 존재할때
     */
    if (pythonEditorSimulationResultWidgetId) {
      reactFlow.setNodes((nodes) => nodes.filter((item) => item.id !== pythonEditorSimulationResultWidgetId));
      afterRemovePythonSimulationWidget();
      return;
    }

    const simulId = getUniqueKey();
    setPythonEditorSimulationResultWidgetId(simulId);
    const {x, y} = reactFlow.getNode(id).position;
    const {width, height} = reactFlow.getNode(id).style;
    const position = {x, y: y + Number(height) + 20};
    reactFlow.addNodes({
      id: simulId,
      type: 'PythonEditorSimulationResultWidget',
      data: {
        title: data.title,
        customizedTitle: false,
        metaData: {
          targetPythonEditorId: id
        },
        minWidth: 300,
        minHeight: 300
      },
      style: {
        minWidth: 300,
        minHeight: 300,
        width,
        height: 300
      },
      zIndex: rest.zIndex,
      position
    });
  };

  const onClickIconModeIcon = () => {
    setIconMode(function (prevState) {
      let prevStyle = null;
      if (prevState) {
        reactFlow.setNodes((nodes) => {
          const node = nodes.find((node) => node.id === id);
          node.style = prevState;
          return nodes;
        });
      } else {
        reactFlow.setNodes((nodes) => {
          const node = nodes.find((node) => node.id === id);
          prevStyle = node.style;
          node.style = {
            width: 343,
            height: 144,
            maxWidth: 343,
            maxHeight: 144,
            minWidth: 343,
            minHeight: 144
          };
          return nodes;
        });
      }
      return prevStyle;
    });
  };

  const getWebsocketHost = (url: string): string => {
    if (url.startsWith('https://')) {
      return url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
      return url.replace('http://', 'ws://');
    }
  };

  const webSocket = useRef<WebSocket | null>(null);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;

  const connectWebSocket = () => {
    const host = getApiHost();
    const websocketHost = getWebsocketHost(host);
    const metaData = data?.metaData as IPythonEditorWidgetData;
    const prevKernelId = metaData?.kernelId;
    webSocket.current = new WebSocket(
      websocketHost + '/ws/python_editor?token=' + token + '&widget_id=' + id + '&kernel_id=' + prevKernelId
    );
    webSocket.current.onopen = () => {};
    webSocket.current.onclose = (event) => {
      webSocket.current = null;
      setIsReady(false);
    };
    webSocket.current.onerror = (error) => {
      webSocket.current = null;
      setIsReady(false);
    };
    webSocket.current.onmessage = (msg) => {
      try {
        const data = JSON.parse(msg.data);
        setIsReady(true);
        setKernelId(data.kernel_id);
      } catch (error) {
        console.error('수신한 메시지를 처리하는 중 오류 발생:', error);
      }
    };
  };

  // useEffect(() => {
  //   const defaultDatabaseHierarchyListKeys = availableDatabaseHierarchyList
  //     ? availableDatabaseHierarchyList.map((v) => v.database)
  //     : [];
  //   if (localDatabase && dynamicHierarchy) {
  //     setPythonDynamicHierarchy({...dynamicHierarchy, database: localDatabase.name});
  //   }
  //   // 연결된 datasheet widget title 을 상태 적용
  //   if (localDatabase && dynamicHierarchy && variableTable) {
  //     setVariableTable(getUpdateVariableTable(true, defaultDatabaseHierarchyListKeys));
  //   }
  //   // datasheet widget 연결 끊어졌을 때 상태 적용
  //   if (!localDatabase && variableTable) {
  //     setPythonDynamicHierarchy(null);
  //     setVariableTable(getUpdateVariableTable(false, defaultDatabaseHierarchyListKeys));
  //   }
  // }, [localDatabase]);

  useEffect(() => {
    subjectRef.current = {
      pythonRunOutflowResult,
      pythonEditorWidgetLogHistory,
      pythonEditorSimulationResultHistory,
      clearLogHistory,
      clearResultHistory,
      afterRemovePythonLogWidget,
      afterRemovePythonSimulationWidget,
      batchRun: runConfig.batchRun
    } as IPythonEditorSubject;
    // TODO: 리렌더링으로 리팩토링 필요
    updateSubjectInfo(id, 'PythonEditorWidget', subjectRef);
  }, [pythonEditorSimulationResultHistory, pythonEditorWidgetLogHistory, pythonRunOutflowResult, runConfig.batchRun]);

  useEffect(() => {
    updateSubjectInfo(id, 'PythonEditorWidget', subjectRef);
  }, []);

  useEffect(() => {
    databaseValueRef.current = databaseValue;
  }, [databaseValue]);

  useEffect(() => {
    if (data?.metaData) {
      const {
        code,
        codeRunResult,
        runConfig,
        variableTable,
        pythonEditorWidgetLogHistory,
        pythonEditorLogWidgetId,
        pythonEditorSimulationResultWidgetId,
        pythonEditorSimulationResultHistory,
        iconMode
      } = data?.metaData as IPythonEditorWidgetData;
      setCode(code || '');
      setCodeRunResult(codeRunResult);
      setRunCfg(runConfig || pythonEditorDefaultStateValue.runConfigState);
      setVariableTable(variableTable || []);
      setPythonEditorWidgetLogHistory(pythonEditorWidgetLogHistory || []);
      setPythonEditorLogWidgetId(pythonEditorLogWidgetId || null);
      setPythonEditorSimultationResultHistory(pythonEditorSimulationResultHistory || []);
      setPythonEditorSimulationResultWidgetId(pythonEditorSimulationResultWidgetId || null);
      setIconMode(iconMode || null);
    }
  }, []);

  useEffect(() => {
    if (!id || !variableTable || variableTable?.length === 0) return;
    const cloudDbVariable = variableTable?.filter((item) => item.variableDbType === 'cloud');
    const cloudNodeInfos = cloudDbVariable.map((item) => {
      const path = item.path;
      if (path.length > 1) {
        const [database, ...rest] = path;
        const hierarchy = rest.slice(0, -1);
        const name = rest[rest.length - 1];
        return {
          name,
          database,
          hierarchy
        };
      }
    });
    latestNodeHandler.renewSubscribe(id, cloudNodeInfos, true);
  }, [id, variableTable]);

  useEffect(() => {
    if (!latestNodeHandler?.latestResult?.[id]) return;
    const cloudRes = [...(latestNodeHandler?.latestResult[id] || [])];
    let newValueGroupObject = {};
    setVariableTable(function (prevState) {
      let newState = prevState;
      if (cloudRes?.length > 0) {
        newState.map(function (row) {
          const targetRes = cloudRes.find((latestNode) => latestNode.key === row.path.join(', '));
          if (targetRes) {
            newValueGroupObject = {...newValueGroupObject, [row.keys]: targetRes};
          }
        });
      }
      setDatabaseValue(newValueGroupObject);
      return newState;
    });
  }, [id, latestNodeHandler?.latestResult]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      webSocket.current?.close(4000);
    };
  }, [id]);

  useEffect(() => {
    data.metaData = {
      ...(data?.metaData || {}),
      variableTable,
      code,
      runConfig,
      codeRunResult,
      pythonEditorLogWidgetId,
      pythonEditorWidgetLogHistory,
      pythonEditorSimulationResultWidgetId,
      pythonEditorSimulationResultHistory,
      iconMode,
      kernelId
    } as IPythonEditorWidgetData;
  }, [
    code,
    codeRunResult,
    data,
    iconMode,
    kernelId,
    pythonEditorLogWidgetId,
    pythonEditorSimulationResultWidgetId,
    pythonEditorSimulationResultHistory,
    pythonEditorWidgetLogHistory,
    runConfig,
    variableTable
  ]);

  useEffect(() => {
    queueResetTimeoutIdRef.current = queueResetTimeoutId;
    isRunRef.current = isRun;
    stopRequestRef.current = stopRequest;
  }, [isRun, queueResetTimeoutId, stopRequestRef]);

  useEffect(() => {}, [iconMode]);

  return (
    <WidgetContainer {...rest} id={id} data={data} type="PythonEditorWidget" iconMode={Boolean(iconMode)}>
      <MessageSpinner isShow={!(Boolean(webSocket.current) && isReady)} type="overlay" delayTime={0.7}>
        {/*{loadingMessage}*/}
      </MessageSpinner>
      <WidgetHeader
        type="PythonEditorWidget"
        icon={data.icon}
        id={id}
        title={getWidgetTitle({data})}
        suffix="- Python Editor"
        onChangeTitle={onChangeTitle}
      />
      {Boolean(iconMode) && <WidgetIconModeHeader title={getWidgetTitle({data})} />}
      <WidgetActionPanel padding={0} height={!iconMode ? 60 : 123} align="center">
        <PythonEditorActionPanel
          iconMode={Boolean(iconMode)}
          isRunState={isRunState}
          isShowEnvironmentPackageListModalState={isShowEnvironmentPackageListModalState}
          runConfigState={runConfigState}
          connectedDatasheetSubjectInfo={localDatabase}
          pythonEditorLogWidgetId={pythonEditorLogWidgetId}
          pythonEditorSimulationResultWidgetId={pythonEditorSimulationResultWidgetId}
          runCode={runCode}
          stopModelRunnerCodeRunning={stopPythonCodeRunning}
          addPythonLogWidget={addPythonLogWidget}
          addPythonSimulationWidget={addPythonSimulationWidget}
          onClickIconModeIcon={onClickIconModeIcon}
        />
      </WidgetActionPanel>
      <WidgetBody ref={boundaryTopRef} actionMenuHeight={60}>
        {Boolean(nodeSelectorTargetInfoState[0]) && (
          <NodeSelectorRevision
            onSelect={onSelectNodeSelector}
            onClose={onCloseNodeSelector}
            //hierarchyInfos={[pythonDynamicHierarchy]}
            hierarchyInfos={[dynamicHierarchy]}
            options={{selectSingleNode: true}}
          />
        )}
        <PythonEditorBodyTop height={editorBodyTopPosition.y}>
          <PythonEditorVariableTable
            variableTableState={variableTableState}
            valueFromDatasheet={valueFromDatasheet}
            valueGroupObjectState={databaseValueState}
            onSetNodeSelectorTargetInfo={onSetNodeSelectorTargetInfo}
          />
        </PythonEditorBodyTop>
        <TopResizeArea ref={boundaryBottomRef}>
          <ResizeHandle ref={dragHandleTopRef} onMouseDown={onMouseDownTopResizeHandle} />
          <PythonEditorBodyBottom height={widgetBottomHeight}>
            <PythonCodeEditor width={editorBodyBottomPosition.x} codeState={codeState} />
            {widgetBottomHeight > 32 && (
              <ResizeHandle type="vertical" ref={dragHandleBottomRef} onMouseDown={onMouseDownBottomResizeHandle} />
            )}
            <PythonCodeResult codRunResultState={codRunResultState} />
          </PythonEditorBodyBottom>
        </TopResizeArea>
        {isShowWidgetModal && (
          <WidgetModal title="Notice" onClose={() => setIsShowWidgetModal(false)}>
            Python code execution failed. <br /> Please check the error message.
          </WidgetModal>
        )}
      </WidgetBody>
      <WidgetConfigLayer
        title="Python Environment Package List"
        isShow={isShowEnvironmentPackageListModal}
        onClose={() => setIsShowEnvironmentPackageListModal(false)}
      >
        <PythonEditorWidgetEnvironmentPackageList onClose={() => setIsShowEnvironmentPackageListModal(false)} />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}
export default PythonEditorWidget;
