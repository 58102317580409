/**
 * 인자로 받은 2개의 object 값이 일치하는 지 여부
 * @param obj1
 * @param obj2
 */
export const objectsAreEqual = <T>(obj1: T, obj2: T): boolean => {
  const keys = Object.keys(obj1);
  for (let key of keys) {
    if (typeof obj1[key] !== typeof obj2[key]) {
      if (String(obj1[key]) !== String(obj2[key])) {
        return false;
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
};
