import {MouseEvent, PropsWithChildren, ReactElement, useContext} from 'react';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import classNames from 'classnames';

const Container = styled.div`
  box-sizing: border-box;
  min-height: 60px;
  flex-shrink: 0;
  background-color: ${({theme}) => theme.color.iceBlue};
  border-top: 1px solid #cfd4d5;
  border-bottom: 1px solid #cfd4d5;
  padding: 10px;

  &.bold {
    min-height: 80px;
  }
`;
const ChildrenWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
`;

type IProps = PropsWithChildren & {
  type?: 'default' | 'bold';
  align?: 'left' | 'center' | 'right';
  height?: number;
  padding?: number;
  disabled?: boolean;
};

function WidgetActionPanel({children, height, padding, align, type, disabled}: IProps): ReactElement {
  return (
    <Container
      className={classNames('widget-action-panel', 'nodrag', type)}
      style={{height, padding}}
      onClick={(e) => e.stopPropagation()}
    >
      <ChildrenWrapper className={classNames({disabled}, align)}>{children}</ChildrenWrapper>
    </Container>
  );
}

export default WidgetActionPanel;
