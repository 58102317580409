import {ChangeEvent, FocusEvent, KeyboardEvent, memo, ReactElement, useState} from 'react';
import classnames from 'classnames';
import {ITableField} from 'components/table/DefaultTable';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  padding: 5px 7px;
  box-sizing: border-box;
  color: #394add;
  span,
  svg {
    cursor: pointer;
  }
  &.custom {
    opacity: 0.8;
    color: #bebebe;
  }
`;

type IProps = {
  field: ITableField;
  th?: boolean;
  keys: string;
  editable: boolean;
  isCurrentTime: boolean;
  value: string;
  variableDbType: 'local' | 'cloud' | 'custom';
  onChange(e: ChangeEvent): void;
  onClick?(rowKey: string, columnKey?: string, key?: string, value?: string[]): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
};

function VariableTablePath({
  keys,
  field,
  editable,
  isCurrentTime,
  onChange,
  th,
  onClick,
  onBlur,
  onKeyDown,
  value: headerValue,
  variableDbType,
  ...rest
}: IProps): ReactElement {
  const value = rest[field?.key];
  const [strArr] = useState<string[]>(value);

  if (th) {
    return <div>{headerValue}</div>;
  } else {
    return (
      <Container className={classnames(rest['flowType'], variableDbType)}>
        <span onClick={() => onClick(field?.key, 'change_path', keys, strArr)}>
          {rest['flowType'] === 'Import'
            ? value?.join('.') || 'Select Path' // Import일 때 value가 있으면 join된 값을, 없으면 'Select Path'
            : rest['flowType'] === 'Export' && value?.join(',')
              ? value?.join('.') // Export일 때는 항상 이 값을 전달
              : 'Select Path'}
        </span>
      </Container>
    );
  }
}

export default memo(VariableTablePath, (prevProps, nextProps) => {
  return prevProps.variableDbType === nextProps.variableDbType && prevProps.editable === nextProps.editable;
});
