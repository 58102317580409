import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {Button} from 'components/forms/index';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  height: 30px;

  .stepper-icon {
    width: 15px;
    height: 15px;
    color: #525f7f;
    &.disabled {
      cursor: default;
    }
  }
`;

const NumInput = styled.input`
  width: 50px;
  height: 100%;
  text-align: center;
  font-size: 15px;
  background-color: #eaeffa;
  border: 1px solid #c7cede;
  color: #475885;
  box-sizing: border-box;
  outline: none;

  &.invalid {
    border-color: ${({theme}) => theme.color.invalid};
    background-color: rgba(255, 45, 45, 0.05);
  }

  &:disabled {
    color: #a6a6a6;
  }

  &:focus {
    background-color: #ffffff;
  }
`;

type IProps = {
  value: number;
  min: number;
  max: number;
  loop?: boolean;
  disabled?: boolean;
  onChange: (num: number) => void;
};

function NumberStepper({value, onChange, min, max, loop = false, disabled = false}: IProps): ReactElement {
  const [invalid, setInvalid] = useState<boolean>(false);
  const disabledMinus = (!loop && value <= min) || disabled;
  const disabledPlus = (!loop && value >= max) || disabled;

  useEffect(() => {
    const outOfRange = Number(value) < min || Number(value) > max;
    const bool = isNaN(Number(value)) || outOfRange;
    setInvalid(bool);
  }, [value]);

  const onChangeNum = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    onChange(Number(value));
  };

  const onClickPlus = () => {
    if (value === max) {
      loop && onChange(min);
    } else {
      onChange(value + 1);
    }
  };

  const onClickMinus = () => {
    if (value === min) {
      loop && onChange(max);
    } else {
      onChange(value - 1);
    }
  };

  return (
    <Container>
      <Button variant="none" onClick={onClickMinus} disabled={disabledMinus}>
        <FontAwesomeIcon className={classnames('stepper-icon', {disabled: disabledMinus})} icon={faMinus} />
      </Button>
      <NumInput className={classnames({invalid})} value={value} onChange={onChangeNum} disabled={disabled} />
      <Button variant={'none'} onClick={onClickPlus} disabled={disabledPlus}>
        <FontAwesomeIcon className={classnames('stepper-icon', {disabled: disabledPlus})} icon={faPlus} />
      </Button>
    </Container>
  );
}
export default NumberStepper;
