import {useRef} from 'react';
import {IMetaPfdWidgetLocalData, IPythonEditorWidgetData, IWidgetMetaData, IWidgetNodeData} from 'components/pc/types';
import useDraggable from 'utils/useDraggable';

function usePythonPos(data: IWidgetNodeData) {
  const boundaryTopRef = useRef<any>(null);
  const dragHandleTopRef = useRef(null);
  const defaultTopPosition = {x: 0, y: 90};
  const defaultTopPadding = {top: 90, right: 0, bottom: 1, left: 0};
  const metaData = data.metaData || {resizeWidth: 550, resizeHeight: 90};
  data.metaData = metaData as IWidgetMetaData;

  const [editorBodyTopPosition, onMouseDownTopResizeHandle, setEditorBodyTopSize] =
    useDraggable<IPythonEditorWidgetData>(
      boundaryTopRef,
      dragHandleTopRef,
      defaultTopPosition,
      defaultTopPadding,
      true,
      metaData as IPythonEditorWidgetData,
      'resizeHeight',
      'y'
    );

  const boundaryBottomRef = useRef(null);
  const dragHandleBottomRef = useRef(null);
  const defaultBottomPosition = {x: 550, y: 0};
  const defaultBottomPadding = {top: 0, right: 192, bottom: 0, left: 250};
  const [editorBodyBottomPosition, onMouseDownBottomResizeHandle, setEditorBodyBottomSize] =
    useDraggable<IPythonEditorWidgetData>(
      boundaryBottomRef,
      dragHandleBottomRef,
      defaultBottomPosition,
      defaultBottomPadding,
      true,
      metaData as IPythonEditorWidgetData,
      'resizeWidth',
      'x'
    );

  const widgetBodyHeight = boundaryTopRef.current?.offsetHeight || 0;
  const widgetBottomHeight = widgetBodyHeight - editorBodyTopPosition.y - 7;

  return {
    boundaryTopRef,
    editorBodyTopPosition,
    boundaryBottomRef,
    dragHandleTopRef,
    onMouseDownTopResizeHandle,
    widgetBottomHeight,
    editorBodyBottomPosition,
    dragHandleBottomRef,
    onMouseDownBottomResizeHandle
  };
}

export default usePythonPos;
