import {ChangeEvent, Dispatch, MouseEvent, SetStateAction, useRef} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CheckboxForm, SelectForm, TextForm} from 'components/forms';
import {
  faCircleInfo,
  faFileSpreadsheet,
  faPause,
  faPlay,
  faScroll,
  faWindowRestore
} from '@fortawesome/pro-light-svg-icons';
import {IDatasheetSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';
import {IPythonEditorRunConfig} from 'components/pc/widgets/pythonEditor/types';
import classNames from 'classnames';
import GroupDivider from 'components/forms/GroupDivider';
import Tooltip from 'components/common/Tooltip';

type styleProps = {
  $iconMode?: boolean;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  padding: 10px;
  box-sizing: border-box;

  &.on {
    flex-direction: column;
    gap: 10px;
  }
  &.off {
    align-items: center;
  }
`;

const Section = styled.div<styleProps>`
  height: 38px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(5) {
    border: none;
  }
  &:last-of-type {
    flex: 1;
    border: none;
    justify-content: space-between;
  }
  .checkbox-form {
    //min-width: ${(props) => (props.$iconMode ? 71 : 170)}px;
    label {
      font-size: 17px;
    }
  }
  .interval-value {
    width: 44px;
    height: 40px;
  }
  .interval-unit {
    width: 120px;
    height: 40px;
  }
  .environment {
    width: 140px;
    height: 40px;
  }

  &.on {
    height: 35px;

    &:last-of-type {
      flex: 0;
    }
    &.bottom {
      padding-top: 10px;
      border-top: 1px solid #acbcc0;
    }
  }
`;
const StyledSelectForm = styled(SelectForm)`
  margin-right: 10px;
  margin-left: 10px;
`;

const PlayButtonWrap = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  &.selected {
    background-color: #154183;
  }

  &:hover {
    background-color: rgba(213, 225, 243, 0.7);
  }
`;

const IntervalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;

  &.on {
    margin-left: 10px;
  }
`;

const BtnWrap = styled.div`
  &.info-btn {
    margin-top: 3px;
  }
  display: flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: -2px;
  border-radius: 3px;
  padding: 5px;
  color: #888;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }

  &.selected {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }
`;

const LastSectionRight = styled.div`
  display: flex;
  margin-left: auto;
  gap: 12px;

  &.off {
    margin-top: 3px;
  }
`;

const intervalUnitOption = [
  {label: 'seconds', value: 1000},
  {label: 'minutes', value: 60 * 1000},
  {label: 'hours', value: 60 * 60 * 1000}
];

type IProps = {
  iconMode: boolean;
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  isShowEnvironmentPackageListModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  runConfigState?: [IPythonEditorRunConfig, Dispatch<SetStateAction<IPythonEditorRunConfig>>];
  connectedDatasheetSubjectInfo?: IWidgetSubject<IDatasheetSubject>;
  pythonEditorLogWidgetId?: string;
  pythonEditorSimulationResultWidgetId?: string;
  runCode(): Promise<unknown>;
  stopModelRunnerCodeRunning(): void;
  addPythonLogWidget(): void;
  addPythonSimulationWidget(): void;
  onClickIconModeIcon(): void;
};
function PythonEditorActionPanel({
  iconMode,
  isRunState,
  isShowEnvironmentPackageListModalState,
  runConfigState,
  connectedDatasheetSubjectInfo,
  pythonEditorLogWidgetId,
  pythonEditorSimulationResultWidgetId,
  runCode,
  stopModelRunnerCodeRunning,
  addPythonLogWidget,
  addPythonSimulationWidget,
  onClickIconModeIcon
}: IProps) {
  const [runCfg, setRunCfg] = runConfigState;
  const [isRun, setIsRun] = isRunState;
  const [, setIsShowEnvironmentPackageListModal] = isShowEnvironmentPackageListModalState;

  const onChangeBooleanConfig = (key: string, value: boolean) => {
    onChangeRunConfig(key, value);
    switch (key) {
      case 'batchRun': {
        setTimeout(() => connectedDatasheetSubjectInfo?.ref?.current?.changeReadOnly?.(value), 0);
        break;
      }
    }
  };

  const onChange = (e: ChangeEvent) => {
    const {name, value} = e.target as HTMLInputElement;
    let refined = value;
    switch (name) {
      case 'intervalValue': {
        if (!(!isNaN(Number(value)) && Number(value) > 0)) refined = '';
        break;
      }
      default:
        break;
    }
    onChangeRunConfig(name, refined);
  };

  const onClickRun = (e: MouseEvent) => {
    e.stopPropagation();
    setIsRun((prev) => !prev);
    if (isRun) {
      stopModelRunnerCodeRunning();
    } else {
      runCode();
    }
  };

  const onChangeRunConfig = (key: string, value) => {
    setRunCfg((prev) => {
      return {...prev, [key]: value};
    });
    // setTimeout(() => {
    //   setRunCfg((prev) => {
    //     return {...prev, [key]: value};
    //   });
    // }, 100);
  };

  const iconModeToggle = iconMode ? 'on' : 'off';

  return (
    <Container className={iconModeToggle}>
      <Section className={iconModeToggle}>
        <PlayButtonWrap onClick={onClickRun}>
          <FontAwesomeIcon icon={isRun ? faPause : faPlay} fontSize={24} />
        </PlayButtonWrap>
        <GroupDivider />
        <CheckboxForm
          name="autoRun"
          checked={runCfg?.autoRun}
          onChange={() => onChangeBooleanConfig('autoRun', !runCfg?.autoRun)}
          label={iconMode ? 'Auto' : 'Autorun every :'}
          labelPosition="right"
          disabled={isRun}
        />
        <IntervalContainer className={iconModeToggle}>
          <TextForm
            name="intervalValue"
            className="interval-value"
            onChange={onChange}
            value={runCfg?.intervalValue}
            disabled={isRun}
            width={50}
            height={35}
            style={{marginRight: 10, textAlign: 'center'}}
          />
          <SelectForm
            name="intervalUnit"
            className="interval-unit"
            onChange={onChange}
            options={intervalUnitOption}
            value={runCfg?.intervalUnit}
            disabled={isRun}
            width={!iconMode ? 120 : 124}
            height={35}
          />
        </IntervalContainer>
        {!iconMode && <GroupDivider />}
      </Section>
      <Section className={classNames(iconModeToggle, 'bottom')} $iconMode={iconMode}>
        <CheckboxForm
          name="batchRun"
          checked={runCfg?.batchRun}
          onChange={() => onChangeBooleanConfig('batchRun', !runCfg?.batchRun)}
          label={iconMode ? 'Batch' : 'Use for batch run'}
          labelPosition="right"
          disabled={!Boolean(connectedDatasheetSubjectInfo) || isRun}
        />
        <GroupDivider />
        {!iconMode && (
          <Tooltip content="Environment Package List" place="left">
            <BtnWrap
              className={classNames(iconMode && 'selected', 'info-btn')}
              onClick={() => setIsShowEnvironmentPackageListModal(true)}
            >
              <FontAwesomeIcon icon={faCircleInfo} fontSize={24} />
            </BtnWrap>
          </Tooltip>
        )}
        {iconMode && <div style={{width: '50px'}} />}

        <LastSectionRight className={iconModeToggle}>
          <Tooltip content={!iconMode ? 'Icon Mode On' : 'Icon Mode Off'} place="left">
            <BtnWrap
              className={classNames(iconMode && 'selected', iconModeToggle)}
              onClick={() => onClickIconModeIcon()}
            >
              <FontAwesomeIcon icon={faWindowRestore} fontSize={18} />
            </BtnWrap>
          </Tooltip>
          {!iconMode && (
            <Tooltip
              content={pythonEditorSimulationResultWidgetId ? 'Close Simulation Result' : 'Open Simulation Result'}
              place="bottom"
            >
              <BtnWrap
                className={classNames(pythonEditorSimulationResultWidgetId && 'selected', iconModeToggle)}
                onClick={() => addPythonSimulationWidget()}
              >
                <FontAwesomeIcon icon={faFileSpreadsheet} fontSize={18} />
              </BtnWrap>
            </Tooltip>
          )}
          {!iconMode && (
            <Tooltip content={pythonEditorLogWidgetId ? 'Close Log' : 'Open Log'} place="bottom">
              <BtnWrap
                className={classNames(pythonEditorLogWidgetId && 'selected', iconModeToggle)}
                onClick={() => addPythonLogWidget()}
              >
                <FontAwesomeIcon icon={faScroll} fontSize={18} />
              </BtnWrap>
            </Tooltip>
          )}
        </LastSectionRight>
      </Section>
    </Container>
  );
}

export default PythonEditorActionPanel;
