import {Handle} from 'reactflow';
import {Position} from '@reactflow/core';
import {WidgetTypes} from 'components/menu/types';
import {IWidgetNodeData} from 'components/pc/types';
import styled from 'styled-components';
import React, {useContext, useEffect, useMemo} from 'react';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import useEdgeInOutNum from 'components/pc/widgets/parts/hooks/useEdgeInOutNum';
import {getEdgeInfo} from 'components/pc/widgets/parts/utils/getEdgeInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowLeftToLine,
  faArrowRightToLine,
  faArrowDownToLine,
  faArrowUpToLine,
  faArrowUpFromLine,
  faArrowRightFromLine,
  faArrowDownFromLine,
  faArrowLeftFromLine
} from '@fortawesome/pro-light-svg-icons';

const FloatingSourceHandle = styled(Handle)`
  z-index: 15;
  &.react-flow__handle-bottom {
    top: 100%;
    transform: translate(0, 0);
    margin-top: -0px;
    margin-left: -21px;
    width: 25px;
    height: 30px;
  }
  &.react-flow__handle-left {
    margin-left: -25px;
    margin-top: -17px;
    width: 30px;
    height: 25px;
  }
  &.react-flow__handle-top {
    margin-left: -17px;
    margin-top: -25px;
    width: 25px;
    height: 30px;
  }
  &.react-flow__handle-right {
    width: 30px;
    height: 25px;
    margin-right: -25px;
    margin-top: -17px;
  }
  &.react-flow__handle.source {
    border-color: transparent;
    border-radius: 0px;
  }
`;

const FloatingTargetHandle = styled(Handle)`
  z-index: 15;
  &.react-flow__handle-bottom {
    top: 100%;
    transform: translate(0, 0);
    margin-top: -7px;
    margin-left: 17px;
  }
  &.react-flow__handle-left {
    margin-left: -5px;
    margin-top: 17px;
  }
  &.react-flow__handle-top {
    margin-top: -4px;
    margin-left: 17px;
  }
  &.react-flow__handle-right {
    margin-right: -5px;
    margin-top: 17px;
  }
  &.react-flow__handle.target {
    border-color: transparent;
  }
`;

const IconSourceWrapper = styled.div`
  pointer-events: none;
  &.top {
    margin-top: -4px;
    margin-left: -3px;
  }
  &.left {
    margin-top: -8px;
    margin-left: -2px;
  }
  &.right {
    margin-top: -8px;
    margin-left: -4px;
  }
  &.bottom {
    margin-left: -4px;
    margin-top: -7px;
  }
`;

const IconTargetWrapper = styled.div`
  pointer-events: none;
  &.top {
    margin-top: -25px;
    margin-left: -8px;
  }
  &.left {
    margin-top: -13px;
    margin-left: -23px;
  }
  &.right {
    margin-top: -13px;
    margin-left: 2px;
  }
  &.bottom {
    margin-left: -8px;
  }
`;

const sourceHandleList = [
  {
    id: 'widget-edge-top',
    position: Position.Top,
    icon: faArrowUpFromLine
  },
  {
    id: 'widget-edge-left',
    position: Position.Left,
    icon: faArrowLeftFromLine
  },
  {
    id: 'widget-edge-bottom',
    position: Position.Bottom,
    icon: faArrowDownFromLine
  },
  {
    id: 'widget-edge-right',
    position: Position.Right,
    icon: faArrowRightFromLine
  }
];

const targetHandleList = [
  {
    id: 'widget-edge-top',
    position: Position.Top,
    icon: faArrowDownToLine
  },
  {
    id: 'widget-edge-left',
    position: Position.Left,
    icon: faArrowRightToLine
  },
  {
    id: 'widget-edge-bottom',
    position: Position.Bottom,
    icon: faArrowUpToLine
  },
  {
    id: 'widget-edge-right',
    position: Position.Right,
    icon: faArrowLeftToLine
  }
];

type IProps = {
  type: WidgetTypes;
  data?: IWidgetNodeData;
  id?: string;
};
function WidgetHandle({type, data, id}: IProps) {
  const {isLineEditingModeState, draggingNodeState} = useContext(ProcessCanvasContext);
  const {edgeIn, edgeOut} = useEdgeInOutNum(id);
  const edgeType = getEdgeInfo(data?.in, data?.out, edgeIn.length, edgeOut.length);
  const [isLineEditingMode] = isLineEditingModeState;
  // line 드래그하는 node id 값
  const [draggingNode, setDraggingNode] = draggingNodeState;
  // line 드래그하는 node 의 import node 정보
  const {edgeIn: draggingEdgeIn} = useEdgeInOutNum(draggingNode);

  useEffect(() => {
    const handleMouseUp = () => {
      setDraggingNode('');
    };
    if (draggingNode) {
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [draggingNode]);

  const sourceVisible = useMemo(
    () =>
      isLineEditingMode &&
      (edgeType === 'both' || edgeType === 'source') &&
      // drag 할 경우 hide
      !draggingNode,
    [draggingNode, edgeType, id, isLineEditingMode]
  );

  const targetVisible = useMemo(
    () =>
      isLineEditingMode &&
      (edgeType === 'both' || edgeType === 'target') &&
      // drag 하지 않는 경우 hide
      draggingNode &&
      // drag 하는 노드일 경우 hide
      draggingNode !== id &&
      // 이전에 연결된 노드일 경우 hide
      (draggingEdgeIn ? !draggingEdgeIn.map((v) => v.id).includes(id) : true),
    [draggingEdgeIn, draggingNode, edgeType, id, isLineEditingMode]
  );

  if (data?.in === 0 && data.out === 0) {
    return undefined;
  }
  if (data?.docked) {
    return undefined;
  }

  return (
    <>
      <div
        onMouseDown={() => {
          setDraggingNode(id);
        }}
      >
        {sourceHandleList.map((handle) => (
          <FloatingSourceHandle
            key={handle.id}
            id={handle.id}
            type="source"
            position={handle.position}
            isConnectableEnd={false}
            isConnectable={sourceVisible}
          >
            <IconSourceWrapper className={handle.position}>
              {sourceVisible && <FontAwesomeIcon icon={handle.icon} size="2xl" />}
            </IconSourceWrapper>
          </FloatingSourceHandle>
        ))}
      </div>
      {targetHandleList.map((handle) => (
        <FloatingTargetHandle
          key={handle.id}
          id={handle.id}
          type="target"
          position={handle.position}
          isConnectableStart={false}
          isConnectable={targetVisible}
        >
          <IconTargetWrapper className={handle.position}>
            {targetVisible && <FontAwesomeIcon icon={handle.icon} color="red" size="2xl" />}
          </IconTargetWrapper>
        </FloatingTargetHandle>
      ))}
    </>
  );
}
export default WidgetHandle;
