import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  memo,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock, faPenToSquare} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import {toSignificantDigit} from 'utils/number-utils';
import {DataContext} from 'api/DataProvider';
import dayjs from 'dayjs';

const Container = styled.div`
  width: 100%;
  position: relative;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  padding: 5px 7px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .value-modify-btn {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    cursor: pointer;
  }

  .value-modify-btn {
    opacity: 0.4;
    transition: all 0.1s;
    &.editable {
      //color: #394add;

      opacity: 1;
    }
    &:hover {
      //color: #394add;
      opacity: 1;
      transition: all 0.1s;
    }
  }
`;

const InputTitle = styled.input`
  width: 100%;
  height: 30px;
  resize: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #b6b4c0;
  padding: 0 10px;
  background: #ffffff;
  text-align: right;

  &:read-only {
    border: none;
    //color: #8a9496;
    background-color: transparent;
    cursor: inherit;
    //pointer-events: none;
  }
`;

type IProps = {
  field: ITableField;
  keys: string;
  editable: boolean;
  isCurrentTime: boolean;
  onChange(e: ChangeEvent): void;
  onClick?(rowKey: string, columnKey?: string, key?: string, value?: string[]): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
  value: string;
  variableDbType: 'local' | 'cloud' | 'custom';
};

function VariableTableValue({
  keys,
  field,
  editable,
  isCurrentTime,
  onChange,
  onClick,
  onBlur,
  onKeyDown,
  value: headerValue,
  variableDbType,
  ...rest
}: IProps): ReactElement {
  const {globalSettingsState} = useContext(DataContext);
  const {significantDigit} = globalSettingsState[0];
  const value = rest[field?.key];
  const [strArr] = useState<string[]>(value);

  const initialValue = isNaN(value)
    ? value === 'NaN'
      ? ''
      : value
    : value === null
      ? ''
      : toSignificantDigit(value, significantDigit);

  const [inputValue, setInputValue] = useState<string | number>(initialValue);
  const [currentTime, setCurrentTime] = useState('');

  const ref = useRef(null);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (isCurrentTime) {
      const timer = setInterval(() => {
        setCurrentTime(dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCurrentTime]);

  useEffect(() => {
    if (currentTime) {
      const event = {
        target: {
          value: currentTime
        }
      } as ChangeEvent<HTMLInputElement>;

      onChangeInput(event);
    }
  }, [currentTime]);

  useEffect(() => {
    if (!editable && !isCurrentTime && variableDbType !== 'custom') {
      setInputValue(initialValue);
    } else {
      ref?.current?.focus();
      setInputValue(toSignificantDigit(inputValue, significantDigit));
    }
  }, [initialValue, editable, isCurrentTime, variableDbType]);

  const onClickVariableValue = () => {
    onClick(field?.key, 'custom_value', keys, strArr);
  };
  const onClickCurrentTimeValue = () => {
    onClick(field?.key, 'current_time', keys, strArr);
  };

  return (
    <Container>
      <InputTitle
        name={keys}
        ref={ref}
        className={editable && 'nodrag'}
        readOnly={!editable}
        value={isCurrentTime ? currentTime : inputValue}
        onBlur={onBlur}
        onChange={onChangeInput}
        onKeyDown={onKeyDown}
      />
      {rest['flowType'] === 'Import' && rest['variableName'] !== 'TimeTag' && (
        <FontAwesomeIcon
          className={classnames('value-modify-btn', editable ? 'editable' : 'disEditable')}
          icon={faPenToSquare}
          onClick={onClickVariableValue}
        />
      )}
      {rest['flowType'] === 'Import' && rest['variableName'] === 'TimeTag' && (
        <FontAwesomeIcon
          className={classnames('value-modify-btn', isCurrentTime ? 'editable' : 'disEditable')}
          icon={faClock}
          onClick={onClickCurrentTimeValue}
        />
      )}
    </Container>
  );
}

export default memo(VariableTableValue, (prevProps, nextProps) => {
  return (
    prevProps.isCurrentTime === nextProps.isCurrentTime &&
    prevProps.value === nextProps.value &&
    prevProps.keys === nextProps.keys &&
    prevProps.editable === nextProps.editable
  );
});
