import {ReactElement, useState} from 'react';
import styled from 'styled-components';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';
import ProjectItem from 'components/pc/widgets/modelRunner/initialize-modal-parts/ProjectItem';
import CreateProjectModal from 'components/pc/widgets/modelRunner/initialize-modal-parts/CreateProjectModal';
import {INextStep} from 'components/pc/widgets/modelRunner/ModelRunnerInitializeModal';
import NoItemDisplay from 'components/common/NoItemDisplay';
import {faFolderOpen} from '@fortawesome/pro-light-svg-icons';
import {faChevronDown, faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TemplateItem from 'components/pc/widgets/modelRunner/initialize-modal-parts/TemplateItem';
import {Button} from 'components/forms';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 600px;
  height: 380px;
  margin-right: 0;
  overflow-y: auto;
`;
const TemplateList = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  transition: height 0.2s;
  flex-shrink: 0;
`;
const ProjectList = styled.div`
  overflow: hidden;
  transition: height 0.2s;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TypeTitle = styled.div`
  color: ${({theme}) => theme.color.secondary};
  height: 30px;
  flex-shrink: 0;
`;
const HorizontalLine = styled.div`
  border-top: 1px solid ${({theme}) => theme.color.optional};
  margin: 10px 0;
`;

type IProps = {
  projectList: IModelRunnerProject[];
  onSelect(params: INextStep): void;
  disabled: boolean;
};

function ProjectItemList({projectList, onSelect, disabled}: IProps): ReactElement {
  const serverTemplateList = projectList.filter((project) => !project.isUserProject);
  const userProjectList = projectList.filter((project) => project.isUserProject);
  const [isOpenTemplate, setIsOpenTemplate] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [projectTemplate, setProjectTemplate] = useState<IModelRunnerProject>(null);

  const onCreateTemplate = (project: IModelRunnerProject): void => {
    // 라이센스 3개 이상 제한
    if (!disabled) {
      setIsShowModal(true);
      setIsOpenTemplate(true);
      setProjectTemplate(project);
    }
  };

  const onConfirmCreate = (project: IModelRunnerProject): void => {
    setIsShowModal(false);
    onSelect({project, type: 'server'});
  };

  const onClickOpen = (project: IModelRunnerProject): void => {
    onSelect({project, type: 'user', remove: false});
  };

  const onClickRemove = (project: IModelRunnerProject): void => {
    onSelect({project, type: 'user', remove: true});
  };

  return (
    <Container className="thin-scrollbar">
      <TypeTitle>
        <Button variant="none" onClick={() => setIsOpenTemplate(!isOpenTemplate)}>
          <FontAwesomeIcon width={20} icon={isOpenTemplate ? faChevronDown : faChevronRight} />
        </Button>
        Get started with<strong> creating a server project template.</strong>
      </TypeTitle>
      <TemplateList className="thin-scrollbar md" style={{height: isOpenTemplate ? 'auto' : 0}}>
        {serverTemplateList?.map((project) => (
          <TemplateItem
            key={project.fileName}
            project={project}
            disabled={disabled}
            onClick={() => onCreateTemplate(project)}
          />
        ))}
      </TemplateList>
      <HorizontalLine />
      <TypeTitle>
        Get started with <strong>opening a user project.</strong>
      </TypeTitle>
      <ProjectList className="thin-scrollbar md">
        {userProjectList?.map((project) => (
          <ProjectItem
            key={project.fileName}
            project={project}
            disabled={disabled && !project.isRunning}
            onClick={() => onClickOpen(project)}
            onRemove={() => onClickRemove(project)}
          />
        ))}
      </ProjectList>
      {userProjectList.length === 0 && (
        <NoItemDisplay margin="10px auto" onClick={() => setIsOpenTemplate(true)}>
          <FontAwesomeIcon icon={faFolderOpen} size="lg" style={{marginRight: 5}} />
          No user project. Please create one first.
        </NoItemDisplay>
      )}
      {isShowModal && (
        <CreateProjectModal
          project={projectTemplate}
          onClose={() => setIsShowModal(false)}
          onConfirm={onConfirmCreate}
        />
      )}
    </Container>
  );
}

export default ProjectItemList;
