import {PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useId, useRef} from 'react';
import {LightningChartContext} from 'components/pc/widgets/common/chart/LightningChartProvider';
import styled from 'styled-components';
import {ITimeSeries, ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';
import {
  Axis,
  AxisOptions,
  AxisScrollStrategies,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  emptyFill,
  FillStyle,
  PointLineAreaSeries,
  PointSeries,
  SolidFill,
  UIBackgrounds,
  UIElementBuilders
} from '@lightningchart/lcjs';
import {IChartAttrs} from 'components/pc/widgets/common/chart/types';
import {
  applyBackground,
  getSeries,
  getSolidFill,
  getYAxisName,
  setAxisXTextColor,
  setAxisYTextColor
} from 'components/pc/widgets/common/chart/common-chart-functions';
import {secondsInUnit} from 'components/forms/TimeSelector';

const Container = styled.div`
  background-color: #e9eff3;
  min-height: 50px;
  overflow: hidden;
  flex-shrink: 0;
`;

const Chart = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

type IProps = PropsWithChildren & {
  docked?: boolean;
  height: number | string;
  background: string;
  textColor: string;
  loader: ITimeSeriesLoader;
  onChangeAttrs(attrs: IChartAttrs): void;
};

function TimeSeriesChartContainer({background, textColor, loader, height, onChangeAttrs}: IProps): ReactElement {
  const ref = useRef(null);
  // const {data} = props;
  const id = useId();
  const lc = useContext(LightningChartContext);
  // const [chartState, setChartState] = useState<{chart: ChartXY; seriesList: PointLineAreaSeries[]}>();
  const chartRef = useRef<undefined | ChartXY>(null);
  const lineSeriesRef = useRef<(PointLineAreaSeries | PointSeries)[]>([]);
  // 받은 라이브 데이터를 누적해서 쌓아둬야 함 -> color, active, YAxis 가 변경 되면 chart 를 다시 그리는데 이때 최초 받은 데이터와 이 데이터를 합쳐 차트를 표현 한다.
  const liveRecordsRef = useRef({});
  const firstTimestampRef = useRef<number>(0);

  const createChart = useCallback((): ChartXY => {
    chartRef.current?.dispose();
    const container = document.getElementById(id) as HTMLDivElement;
    if (!container || !lc) {
      return;
    }
    const defaultAxisX: AxisOptions = {type: 'linear-highPrecision'};
    const chart = lc.ChartXY({container, defaultAxisX});

    const backgroundStyle = new SolidFill({color: ColorHEX(background)}) as unknown as FillStyle;
    chart
      .setAnimationsEnabled(false)
      .setTitle('')
      .setBackgroundFillStyle(backgroundStyle)
      .setSeriesBackgroundFillStyle(emptyFill as FillStyle)
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.DateTime);
    // .setInterval(defaultRangeInterval);

    return chart;
  }, [id, lc]);

  useEffect(() => {
    return () => {
      // Destroy chart when component lifecycle ends.
      chartRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current = createChart();
  }, [createChart]);

  // chart 의 배경색 설정
  useEffect(() => {
    if (!chartRef.current || chartRef.current.isDisposed()) return;
    applyBackground(chartRef.current, background);
  }, [background]);

  // chart 의 글자색 설정
  useEffect(() => {
    if (!chartRef.current || chartRef.current.isDisposed()) return;
    setAxisYTextColor(chartRef.current.getDefaultAxisY(), textColor);
    setAxisXTextColor(chartRef.current.getDefaultAxisX(), textColor);
  }, [textColor]);

  useEffect(() => {
    if (!chartRef.current || loader.series.length === 0 || chartRef.current.isDisposed()) return;

    const create = (): void => {
      chartRef.current = createChart();

      // yAxis 객체 묶음
      const yAxisList = [];
      let stackCount = 0;

      const parallelList = loader.series.map((ch) => ch.parallel);
      // series 를 기준으로 만들어야 할 yAxis 를 계산

      // 시리즈 중 가장 빠른 시간을 리턴
      const getFirstTimestamp = (series: ITimeSeries[]): number => {
        return series.map((ch) => ch?.data?.[0]?.[0]).sort()?.[0];
      };

      const firstTimestamp = getFirstTimestamp(loader.series);
      const dateOrigin = new Date(firstTimestamp);
      firstTimestampRef.current = firstTimestamp;

      lineSeriesRef.current = loader.series.map((channel) => {
        if (!channel.active) return null;

        const lineStyle = getSolidFill(channel.color);
        const sharedLineStyle = getSolidFill('#ffffff');

        let axisY: Axis;
        const foundYAxis: Axis = yAxisList[channel.parallel];
        const foundStackInfo = loader.stackInfo?.find((info) => info.parallel === channel.parallel);
        const parallelLength = parallelList.filter((num) => num === channel.parallel).length;
        const channelMinHeight = (chartRef.current.getSizePixels().y - 80) / parallelLength;

        if (foundYAxis && !foundStackInfo?.stacked) {
          axisY = foundYAxis;
          axisY
            .setTitle(axisY?.getTitle() + ', ' + getYAxisName(channel.keys, channelMinHeight))
            .setTitleFillStyle(sharedLineStyle);

          setAxisYTextColor(axisY, textColor);
        } else {
          // yAxis 를 새로 만들어야 하는 case
          axisY = chartRef.current
            .addAxisY({iParallel: -channel.parallel, iStack: stackCount})
            .setTitleEffect(undefined)
            .setChartInteractionZoomByWheel(false)
            .setChartInteractionPanByDrag(!loader.isLive)
            .setMargins(10, 10)
            .setTitle(getYAxisName(channel.keys, channelMinHeight))
            .setTitleFillStyle(lineStyle)
            .setThickness(80);

          setAxisYTextColor(axisY, textColor);

          if (!foundYAxis && parallelLength > 1) {
            const {stackInfo, setStackInfo, refreshSeries} = loader;

            const isOn = stackInfo?.find((info) => info.parallel === channel.parallel)?.stacked;
            chartRef.current
              .addUIElement(
                UIElementBuilders.CheckBox.setBackground(UIBackgrounds.None),
                chartRef.current.coordsRelative
              )
              .setEffect(undefined)
              .setPosition({x: 80 * axisY.getParallelIndex() * -1 + 60, y: 22})
              .setText('stack')
              .setTextFillStyle(getSolidFill(isOn ? '#00a6cb' : '#B6B4C0'))
              .setButtonOnFillStyle(getSolidFill('#00a6cb'))
              .setButtonOffFillStyle(getSolidFill('#B6B4C0'))
              .setOn(isOn)
              .setEffect(false)
              .onMouseClick((obj, event, info) => {
                // console.log('stack button:', obj.getOn(), event, info);
                // file 저장을 위해 loader 에 저장. chart 의 업데이트는 여기서 직접 일으킨다.
                setStackInfo((prev) => {
                  return prev
                    .reduce((acc, cur) => {
                      if (!acc.some((info) => info.parallel === cur.parallel)) {
                        acc.push(cur);
                      }
                      return acc;
                    }, [])
                    .map((info) => {
                      if (info.parallel === channel.parallel) {
                        info.stacked = !info.stacked;
                      }
                      return info;
                    });
                });
                refreshSeries();
              });
          }
          stackCount--;
          yAxisList[channel.parallel] = axisY;
        }

        const liveData = liveRecordsRef.current?.[channel.name] || [];

        const mergedData = () => {
          const baseData = channel.data.map(([timestamp, value]) => ({
            x: timestamp - firstTimestamp,
            y: value
          }));
          return loader.isLive === false ? baseData : baseData.concat(liveData);
        };

        return getSeries(chartRef.current, axisY, channel).add(mergedData());
      });

      chartRef.current
        .getDefaultAxisX()
        .setTickStrategy(AxisTickStrategies.DateTime, (tickStrategy) => tickStrategy.setDateOrigin(dateOrigin))
        .setInterval({start: 0, end: 1000 * 60 * 60 * 24 * 7, animate: false})
        .setAnimationScroll(false)
        .setChartInteractionPanByDrag(!loader.isLive)
        .setScrollStrategy(AxisScrollStrategies.fitting)
        .fit()
        .onIntervalChange((axis, start, end) => {
          onChangeAttrs?.({axisX: {interval: {start, end}}});
        });

      chartRef.current.getDefaultAxisY().setVisible(false);
      setAxisXTextColor(chartRef.current.getDefaultAxisX(), textColor);
      applyBackground(chartRef.current, background);
    };
    create();

    /*lineSeriesRef.current.forEach((series) => {
      // series.dispose();
      if (series) {
        series.clear();
        series.axisY.dispose();
        series.dispose();
      }
    });*/
  }, [loader.series, createChart, textColor, background]);

  const refineChartPoint = (point: number[]): {x: number; y: number} => {
    const [timestamp, value] = point;
    const x = timestamp - (firstTimestampRef.current || 0);
    const y = value === null ? value : Number(value);
    return {x, y};
  };

  // 사용자가 liveUnitRange 를 변경하면 데이터 초기화
  useEffect(() => {
    liveRecordsRef.current = {};
  }, [loader.liveUnitRange]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (loader.isLive) {
      const run = (): void => {
        const {liveUnitRange} = loader;
        if (!liveUnitRange) return;
        const end = new Date().getTime() - firstTimestampRef.current;
        const start = end - secondsInUnit[liveUnitRange.unit] * liveUnitRange.value * 1000;
        // console.log('<<< ', start, end, firstTimestampRef.current);
        chartRef.current.getDefaultAxisX().setInterval({start, end, animate: 500});
        timerId = setTimeout(run, 500);
      };
      run();
    } else {
      // 누적 라이브 데이터를 초기화
      liveRecordsRef.current = {};
      clearTimeout(timerId);
      // chartRef.current?.getDefaultAxisX()?.fit(); //.setInterval({start: 0, end: 1000 * 60 * 60 * 24 * 7, animate: 500});
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [loader.isLive, loader.liveUnitRange]);

  useEffect(() => {
    if (!loader?.liveData?.length) return;

    lineSeriesRef.current.forEach((ch) => {
      const name = ch?.getName();
      const found = loader.liveData.find((data) => data.node.join('-') === name);
      if (!found) return;
      const prev = liveRecordsRef.current?.[name] || [];
      const dataset = found.records.map(([timestamp, value]) => ({
        x: timestamp * 1000 - firstTimestampRef.current,
        y: Number(value)
      }));
      // 지금까지의 라이브 데이터를 모두 누적하여 보관
      liveRecordsRef.current[name] = prev.concat(dataset);
      ch?.add(dataset);
    });
  }, [loader.liveData]);

  return (
    <Container style={{height}} ref={ref}>
      <Chart id={id} />
    </Container>
  );
}

export default TimeSeriesChartContainer;
