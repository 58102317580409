import {useContext} from 'react';
import {LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {WidgetTypes} from 'components/menu/types';

type IReturn = {
  edgeIn: {id: string; type: WidgetTypes; connectionType: 'target' | 'source'}[];
  edgeOut: {id: string; type: WidgetTypes; connectionType: 'target' | 'source'}[];
};

function useEdgeInOutNum(id: string): IReturn {
  const {connectionInfo} = useContext(LocalDatabaseContext);

  if (!connectionInfo || !id) {
    return {
      edgeIn: [],
      edgeOut: []
    };
  }

  if (connectionInfo && connectionInfo?.[id]) {
    const edgeIn = connectionInfo[id].filter((info) => info.connectionType === 'source');
    const edgeOut = connectionInfo[id].filter((info) => info.connectionType === 'target');
    return {
      edgeIn,
      edgeOut
    };
  }
  return {
    edgeIn: [],
    edgeOut: []
  };
}

export default useEdgeInOutNum;
