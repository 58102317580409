import {ReactElement, useContext, useEffect, useState} from 'react';
import {getUniqueKey} from 'utils/commons';
import {useReactFlow} from 'reactflow';
import {IChatBody} from 'components/menu/copilot/types';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {pcFunc} from 'utils/processCanvas-functions';
import {getApiHost} from 'api/function';

const MessageImage = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
  align-items: center;
  cursor: default;

  &.clickable {
    cursor: pointer;
  }

  p {
    font-size: 15px;
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 60px;
  flex-shrink: 0;
  img {
    object-fit: cover;
    overflow-clip-margin: unset;
  }
`;

/*
const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
*/

// const API_HOST = process.env.REACT_APP_API_HOST;
const API_HOST = getApiHost();

function CopilotImageCard({message}: IChatBody): ReactElement {
  const {file} = useContext(ProcessCanvasContext);
  const reactFlow = useReactFlow();
  const [imgSrc, setImgSrc] = useState('');

  const onClickTest = (): void => {
    // Process Canvas File 이 없으면 표시못하므로 여기서 중단
    if (!file) return;

    // todo: 화면에 위치해 있는 기존 widget 과 아래위로 겹치지 않는 위치를 구하는 로직 필요 (모든 Widget 적용 해야 함)
    const position = {x: 430, y: 50};
    const id = getUniqueKey();
    const data = {src: API_HOST + '/gpt/images/' + message.image, minWidth: 368, minHeight: 200};
    // todo: 이미지 크기를 측정하여 아래 style 을 세팅 해 줄 필요 있음
    const style = {
      width: 600,
      height: 400
    };
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    reactFlow.addNodes({id, type: 'ImageWidget', data, style, position, zIndex: highestZIndex});
  };

  useEffect(() => {
    if (message.image && typeof message.image === 'string') {
      setImgSrc(API_HOST + '/gpt/images/' + message.image);
    }
  }, [message.image]);

  return (
    <MessageImage className={file && 'clickable'} onClick={onClickTest}>
      <ImageContainer>
        <img src={imgSrc} alt="Copilot Generated Image" width="100%" height="100%" />
      </ImageContainer>
      <p>Your Requested Image</p>
    </MessageImage>
  );
}

export default CopilotImageCard;
