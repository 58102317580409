import {ReactElement, MouseEvent} from 'react';
import styled from 'styled-components';
import {faFolderOpen, faPlugCircleBolt, faPlugCircleXmark, faTrashCan} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';
import classNames from 'classnames';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 0 15px;
  cursor: default;
  border-radius: 5px;

  &.disconnected {
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.disconnected:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.color.iceBlue};
    &.disabled {
      cursor: default;
      background: none;
    }
  }

  svg {
    opacity: 0.4;
    &.delete-icon:hover {
      opacity: 1;
      color: ${({theme}) => theme.color.dark};
      cursor: pointer;
    }
  }
`;

const ProjectType = styled.div`
  width: 140px;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;

  &.disconnected {
    color: ${({theme}) => theme.color.optional};
  }
`;
const RunningLamp = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  flex-shrink: 0;
  margin-left: auto;
`;

const Title = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type IProps = {
  project: IModelRunnerProject;
  className?: string;
  disabled: boolean;
  onClick(): void;
  onRemove(): void;
};

function ProjectItem({project, className, disabled, onClick, onRemove}: IProps): ReactElement {
  // 현재 다른 사용자가 사용하고 있으면 activated
  const disconnected = !Boolean(project.pmvUser);
  const connectIcon = disconnected ? faPlugCircleXmark : faPlugCircleBolt;
  const connectColor = disconnected ? '#bbb' : '#0aa8ec';
  const lampColorStyle = {backgroundColor: project.isRunning ? '#0aa8ec' : '#ddd'};
  const tempUserName = project.pmvUser ? project.pmvUser.split('-key_')[0] : 'disconnected';

  const onClickItem = (): void => {
    if (disconnected) {
      onClick();
    }
  };

  const onClickRemoveItem = (e: MouseEvent): void => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <Container
      className={classNames(className, {disconnected}, disabled === true ? 'disabled' : 'enabled')}
      onClick={onClickItem}
    >
      <FontAwesomeIcon icon={faFolderOpen} size="lg" />
      <Title>{project.alias}</Title>
      <RunningLamp style={lampColorStyle} title={project.isRunning ? 'Running' : 'Idle'} />
      <FontAwesomeIcon icon={connectIcon} size="lg" color={connectColor} />
      <ProjectType className={classNames({disconnected})}>{tempUserName}</ProjectType>
      {!project.isRunning ? (
        <FontAwesomeIcon className="delete-icon" icon={faTrashCan} size="lg" onClick={onClickRemoveItem} />
      ) : (
        <div style={{width: '16px'}}></div>
      )}
    </Container>
  );
}

export default ProjectItem;
