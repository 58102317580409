import {Position} from '@reactflow/core';
import {internalsSymbol, Node} from 'reactflow';

export type PortType = 'source' | 'target';
export type ValueOfPositionType = (typeof Position)[keyof typeof Position];

const getNodeCenter = (node: Node) => {
  return {
    x: node.positionAbsolute.x + node.width / 2,
    y: node.positionAbsolute.y + node.height / 2
  };
};

const getHandleCoordsByPosition = (node: Node, handlePosition: ValueOfPositionType, port: PortType) => {
  const handle = node[internalsSymbol].handleBounds[port].find((h) => h.position === handlePosition);
  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }
  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  const offsetPort = port === 'source' ? 28 : 8;
  if (handlePosition === Position.Right) {
    return [x - offsetPort, y];
  }
  if (handlePosition === Position.Bottom) {
    return [x, y - offsetPort];
  }
  if (handlePosition === Position.Left) {
    return [x + offsetPort, y];
  }
  if (handlePosition === Position.Top) {
    return [x, y + offsetPort];
  }

  return [x, y];
};

const getParams = (nodeA: Node, nodeB: Node, port: PortType) => {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);
  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position, port);

  return [x, y, position];
};

export const getEdgeParams = (source: Node, target: Node) => {
  const [sx, sy, sourcePos] = getParams(source, target, 'source');
  const [tx, ty, targetPos] = getParams(target, source, 'target');
  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos
  };
};

export const getXButtonParams = (pos: ValueOfPositionType, x: number, y: number) => {
  switch (pos) {
    case 'top':
      return [x + 15, y];
    case 'left':
      return [x - 20, y - 7];
    case 'right':
      return [x + 10, y - 10];
    case 'bottom':
      return [x + 20, y + 40];
  }
};
