import React, {ChangeEvent, ReactElement, useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import {objectsAreEqual} from 'utils/object-utils';
import {FormRow, TextForm} from 'components/forms';
import ModalFooter from 'components/common/modal/ModalFooter';
import Button from 'components/forms/Button';

const Container = styled.div`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export type IDataSheetConfigs = {
  startRowIdx: number;
  startColumnIdx: number;
};

type IProps = {
  configs: IDataSheetConfigs;
  onConfirm(configs: IDataSheetConfigs): void;
  onCancel(): void;
  defaultValues: IDataSheetConfigs;
};

function DataSheetConfigs({configs, onConfirm, onCancel, defaultValues}: IProps): ReactElement {
  const [cfg, setCfg] = useState<IDataSheetConfigs>(configs);
  const {showModal, hideModal} = useContext(CommonContext);
  const disabledNoneButton = useMemo(() => objectsAreEqual<IDataSheetConfigs>(cfg, defaultValues), [cfg]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setCfg({...cfg, [name]: Number(value)} as IDataSheetConfigs);
  };

  const onClickConfirm = (): void => {
    onConfirm(cfg);
  };

  const onRestore = () => {
    showModal({
      title: 'Restore Defaults',
      content: 'Do you want to restore default?',
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      isShowCancel: true,
      onConfirm: () => {
        setCfg(defaultValues);
      },
      onClose() {
        hideModal();
      }
    });
  };
  return (
    <Container>
      <FormRow header="Start row idx">
        <TextForm
          width={100}
          type="number"
          name="startRowIdx"
          position="right"
          value={cfg.startRowIdx}
          onChange={onChange}
          height={35}
        />
      </FormRow>
      <FormRow header="Start column idx">
        <TextForm
          width={100}
          type="number"
          name="startColumnIdx"
          position="right"
          value={cfg.startColumnIdx}
          onChange={onChange}
          height={35}
        />
      </FormRow>
      <ModalFooter>
        <Button style={{marginRight: 'auto'}} variant="link" disabled={disabledNoneButton} onClick={onRestore}>
          Restore Defaults
        </Button>
        <Button width={100} onClick={onClickConfirm}>
          Confirm
        </Button>
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default DataSheetConfigs;
