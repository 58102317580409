import {Html} from '@react-three/drei';
import ThreeLandscapeLabel from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabel';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {useContext, useEffect} from 'react';
import {IWidgetMetaData} from 'components/pc/types';
import {IManualCamera, ISelectedLabelInfo} from 'components/pc/widgets/ThreeViewer/ThreeLandscapeWidget';
import * as THREE from 'three';
import {Vector3Like} from 'three';
import {useThree} from '@react-three/fiber';
import {IAnnotationDataType} from 'components/pc/widgets/three/constants/annotations-data-type';
import {DataContext} from 'api/DataProvider';

type IProps = {
  labelsVisible: boolean;
  moveCamera(position: Vector3Like, camera: IManualCamera, scene: THREE.Scene, targetSize?: 'big' | 'small'): void;
  setIsShowNodeSelector(isShowNodeSelector: boolean): void;
  // TODO: selectedLabel, setSelectedLabel 한 번에 받기 (context 등 사용)
  selectedLabel?: ISelectedLabelInfo;
  setSelectedLabel?(labelInfo: ISelectedLabelInfo): void;
  dataList: IAnnotationDataType;
  onSelectBlockNode?(node: string[]): void;
};

function ThreeLandscapeLabelsWrapper({
  labelsVisible,
  moveCamera,
  setIsShowNodeSelector,
  selectedLabel,
  setSelectedLabel,
  dataList,
  onSelectBlockNode
}: IProps) {
  const {camera, scene} = useThree();
  const {availableDatabaseHierarchyList} = useContext(DataContext);
  useEffect(() => {
    if (selectedLabel?.labelPosition && selectedLabel?.leftMenuClicked) {
      moveCamera(selectedLabel.labelPosition as Vector3Like, camera, scene, 'small');
    }
  }, [selectedLabel]);

  const [createContextMenu] = useContextMenu();
  const {addWidget} = useProcessCanvasCommand();
  // 라벨 클릭 시 작동하는 로직 => TODO: 우 클릭 시 모달 띄우는 로직 추가
  const onClickThreeLandscapeLabelsWrapper = (event, info: any): void => {
    event.stopPropagation();

    const selectedLabelInfo: ISelectedLabelInfo = {
      labelName: info.title,
      labelType: info.labelType === 'streams' ? 'streams' : info.labelType === 'equipment' ? 'blocks' : 'undefined',
      labelPosition: {
        x: info.position[0],
        y: info.position[1],
        z: info.position[2]
      },
      clickedPosition: {
        x: event.clientX,
        y: event.clientY
      }
    };
    setSelectedLabel(selectedLabelInfo);

    if (event.button === 2) {
      switch (info.labelType) {
        case 'streams': {
          const metaData: IWidgetMetaData = {
            list: [
              {
                stream: info.title
              }
            ],
            descriptions: null,
            config: {
              autoUpdate: true,
              updateIntervalUnit: 10,
              updateIntervalVal: '10'
            },
            isInitialized: true
          };
          const list = [
            {
              label: 'View Stream Detail',
              value: 'move-camera',
              callback() {
                moveCamera(selectedLabelInfo.labelPosition as Vector3Like, camera, scene);
              }
            },
            {
              label: 'Export to HMB Table',
              value: 'open-hmb-widget',
              callback() {
                // TODO: addWidget 함수를 아래의 equipment처럼 ThreeLandscapeWidget에서 호출하는 방법 생각하기
                addWidget(
                  'hmb',
                  selectedLabelInfo.clickedPosition.x + 50,
                  selectedLabelInfo.clickedPosition.y + 50,
                  info.title,
                  metaData || null
                );
              }
            }
          ];
          createContextMenu({list, event, title: info.title || 'Undefined Label'});
          break;
        }
        case 'equipment': {
          const list = [
            {
              label: 'View Block Detail',
              value: 'move-camera',
              callback() {
                moveCamera(selectedLabelInfo.labelPosition as Vector3Like, camera, scene);
              }
            },
            {
              label: 'Export to Datasheet',
              value: 'open-datasheet-widget',
              callback() {
                // 기존 select modal 여는 로직 주석 처리
                //setIsShowNodeSelector(true);

                // TODO: subnode 계층적으로 가지고 있는 block 데이터도 같이 보일 수 있게 구현
                const blockNodeList = availableDatabaseHierarchyList
                  .filter((list) => list.database === 'model_fcc')[0]
                  .data[0].subnode.find((node) => node.name === 'Blocks')
                  .subnode.filter((sn) => sn.name === selectedLabel.labelName)[0]
                  .subnode.map((v) => JSON.stringify(['model_fcc', 'FCC', 'Blocks', selectedLabel.labelName, v.name]));

                onSelectBlockNode(blockNodeList);
              }
            }
          ];
          createContextMenu({list, event, title: info.title || 'Undefined Label'});
          break;
        }
      }
    }
  };
  return (
    <>
      {dataList.map((item, i /* Annotations HERE */) => (
        <Html
          key={i}
          position={[item.position[0] / 5, item.position[1] / 5, item.position[2] / 5]}
          style={{cursor: 'pointer'}}
        >
          <div
            onClick={(e) => onClickThreeLandscapeLabelsWrapper(e, item)}
            onContextMenu={(e) => onClickThreeLandscapeLabelsWrapper(e, item)}
          >
            <ThreeLandscapeLabel
              title={item.title}
              description={item.description}
              visible={labelsVisible}
              type={item.labelType}
              selected={selectedLabel && selectedLabel.labelName === item.title}
            />
          </div>
        </Html>
      ))}
    </>
  );
}

export default ThreeLandscapeLabelsWrapper;
