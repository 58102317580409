import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import Monaco from '@monaco-editor/react';

const Container = styled.div`
  flex-shrink: 0;

  .ace_scrollbar-v {
    width: 10px !important;

    &::-webkit-scrollbar {
      background-color: rgba(255, 255, 255, 0.0784);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
    }

    .ace_scrollbar-inner {
      width: 10px !important;
    }
  }
`;

type IProps = {
  codeState?: [any, Dispatch<SetStateAction<any>>];
  width?: number;
  // afterChangeMetaData(params: string, value: any): void;
};

function PythonCodeEditor({codeState, width}: IProps) {
  const [code, setCode] = codeState;

  const onChangeCode = (value: string) => {
    setCode(value);
    // afterChangeMetaData('code', value);
  };

  return (
    <Container style={{width}}>
      <Monaco
        height="100%"
        width="100%"
        defaultLanguage="python"
        defaultValue="# Write your Python code here"
        value={code}
        theme="vs-dark"
        onChange={(value) => onChangeCode(value)}
        // onMount={handleEditorDidMount}
        options={{
          fontSize: 15,
          minimap: {enabled: false},
          scrollBeyondLastLine: false
        }}
      />
    </Container>
  );
}

export default PythonCodeEditor;
