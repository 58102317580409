import {ReactElement, useState} from 'react';
import NewAccordionHeader from 'components/common/arrocdion/NewAccordionHeader';
import NewAccordionDetails from 'components/common/arrocdion/NewAccordionDetails';
import styled from 'styled-components';
import {IAccordionItem} from 'components/pc/widgets/modelRunner/ModelRunnerResult';

const Container = styled.div`
  background-color: #d3d3d3;
`;

type IProps = {
  data: IAccordionItem;
};
function AccordionItem({data}: IProps): ReactElement {
  // 열고 닫음
  const [isActive, setIsActive] = useState(data.isActive);
  // 비교를 위해 이전 값 저장
  const [updatedAt, setUpdatedAt] = useState<Date>(new Date());

  const onClick = () => {
    setIsActive(!isActive);
    setUpdatedAt(data.updatedAt);
  };
  return (
    <Container onClick={onClick}>
      <NewAccordionHeader
        isActive={isActive}
        updated={updatedAt?.toString() !== data?.updatedAt?.toString() && Boolean(data?.updatedAt?.toString())}
      >
        {data.title}
      </NewAccordionHeader>
      <NewAccordionDetails isActive={isActive}>{data.value}</NewAccordionDetails>
    </Container>
  );
}

export default AccordionItem;
