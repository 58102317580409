import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import {IComponentTableField} from 'components/table/ComponentTable';
import {IValueGroupObj} from 'components/pc/widgets/pythonEditor/types';
import useLatestNodeHandler, {ILatestNode} from 'hooks/useLatestNodeHandler';
import {IModelRunnerVariable, INodeSelectorTargetInfo} from 'components/pc/widgets/modelRunner/types';
import useDraggable from 'utils/useDraggable';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import ModelRunnerImportTable from 'components/pc/widgets/modelRunner/ModelRunnerImportTable';
import ModelRunnerExportTable from 'components/pc/widgets/modelRunner/ModelRunnerExportTable';
import {IModelRunnerLoader} from 'components/pc/widgets/modelRunner/useModelRunnerLoader';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {DatabaseHierarchy} from 'api/data-types';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import {DataContext} from 'api/DataProvider';
import VariableTableValue from 'components/table/parts/VariableTableValue';
import VariableTablePath from 'components/table/parts/VariableTablePath';

const Container = styled.div``;

const ResizeArea = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
`;

const fields: IComponentTableField[] = [
  {
    key: 'variableName',
    label: 'Variable Name',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '244px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        fontWeight: 400,
        //todo
        // backgroundColor: type === 'Import' ?  '#f2f2f2',
        padding: '5px 7px',
        boxSizing: 'border-box'
      }
    }
  },
  {
    key: 'flowType',
    label: 'Type',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '131px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        fontWeight: 400,
        padding: '5px 7px',
        boxSizing: 'border-box'
      }
    }
  },
  {
    key: 'variableValue',
    label: 'Value',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '220px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        padding: 0,
        boxSizing: 'border-box'
      }
    },

    component: VariableTableValue
  },
  {
    key: 'path',
    label: 'Import Path Info',
    css: {body: 'ellipsis'},
    style: {
      header: {
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: VariableTablePath,
    customHeaderComponent: true
  }
];

// Import 테이블용 필드
const importFields: IComponentTableField[] = fields.map((field) => {
  if (field.key === 'path') {
    return {
      ...field,
      label: 'Import Path Info'
    };
  }
  return field;
});

// Export 테이블용 필드
const exportFields: IComponentTableField[] = fields.map((field) => {
  if (field.key === 'path') {
    return {
      ...field,
      label: 'Export Path Info'
    };
  }
  return field;
});

type IProps = {
  loader: IModelRunnerLoader;
  id: string;
  dynamicHierarchy: DatabaseHierarchy;
  valueGroupObjectState?: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
  valueFromDatasheet?: {
    [key: string]: string;
  };
  widgetBottomHeight: number;
  boundaryTopRef: MutableRefObject<HTMLDivElement>;
};

function ModelRunnerVariableTable({
  loader,
  id,
  dynamicHierarchy,
  valueGroupObjectState,
  valueFromDatasheet,
  widgetBottomHeight,
  boundaryTopRef
}: IProps): ReactElement {
  const {importList, exportList, variableTable} = loader;
  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  const {availableDatabaseHierarchyList} = useContext(DataContext);
  const [valueGroupObject, setValueGroupObject] = valueGroupObjectState;
  const [importRows, setImportRows] = useState<IModelRunnerVariable[]>([]);
  const [exportRows, setExportRows] = useState<IModelRunnerVariable[]>([]);
  const {localDatabase} = useLocalDatabase(id);
  const [nodeSelectorTargetInfo, setNodeSelectorTargetInfo] = useState<INodeSelectorTargetInfo>(null);
  //const [modelDynamicHierarchy, setModelDynamicHierarchy] = useState<DatabaseHierarchy>();

  const getUpdateVariableTable = (isConnected: boolean, keys: string[]) => {
    if (keys.length > 0) {
      return variableTable.map((row) => {
        if (!keys.includes(row.path[0]) && row.path.length > 0) {
          return {
            ...row,
            path: isConnected ? [localDatabase.name, row.path[1]] : [],
            variableValue: !isConnected && 'NaN'
          };
        }
        return row;
      });
    }
    return variableTable;
  };

  // useEffect(() => {
  //   const defaultDatabaseHierarchyListKeys = availableDatabaseHierarchyList
  //     ? availableDatabaseHierarchyList.map((v) => v.database)
  //     : [];
  //
  //   // 연결된 datasheet widget title 을 상태 적용
  //   if (localDatabase && dynamicHierarchy) {
  //     setModelDynamicHierarchy({...dynamicHierarchy, database: localDatabase.name});
  //   }
  //   if (localDatabase && dynamicHierarchy && variableTable) {
  //     loader.setVariableTable(getUpdateVariableTable(true, defaultDatabaseHierarchyListKeys));
  //   }
  //   // datasheet widget 연결 끊어졌을 때 상태 적용
  //   if (!localDatabase && variableTable) {
  //     setModelDynamicHierarchy(null);
  //     loader.setVariableTable(getUpdateVariableTable(false, defaultDatabaseHierarchyListKeys));
  //   }
  // }, [localDatabase]);

  // const importList = selectedProject?.ioInfo?.find((item) => item.Type === 'Import')?.Data;
  // const exportList = selectedProject?.ioInfo?.find((item) => item.Type === 'Export')?.Data;

  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);

  const defaultPosition = {x: 0, y: 226};
  const defaultPadding = {top: 44, right: 0, bottom: 1, left: 0};
  const [editorBodyPosition, onMouseDownResizeHandle, setEditorBodySize] = useDraggable(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding
  );

  const openState = useState<boolean>(true);

  const [isOpen, setIsOpen] = openState;
  const prevPositionY = useRef(editorBodyPosition.y);

  useEffect(() => {
    if (!isOpen) {
      prevPositionY.current = editorBodyPosition.y;
      setEditorBodySize({x: 0, y: 44});
    } else {
      setEditorBodySize({x: 0, y: prevPositionY.current});
    }
  }, [isOpen]);

  useEffect(() => {
    if (editorBodyPosition.y > 44) {
      setIsOpen(true);
      prevPositionY.current = editorBodyPosition.y;
    } else {
      setIsOpen(false);
    }
  }, [editorBodyPosition.y]);

  useEffect(() => {
    if (!latestNodeHandler?.latestResult?.[id]) return;

    const cloudRes = [...(latestNodeHandler?.latestResult[id] || [])];
    let newValueGroupObject = {};
    loader.setVariableTable(function (prevState) {
      let newState = prevState;
      if (cloudRes?.length > 0) {
        newState.map(function (row) {
          const targetRes = cloudRes.find((latestNode) => latestNode.key === row.path.join(', '));
          if (targetRes) {
            newValueGroupObject = {...newValueGroupObject, [row.keys]: targetRes};
          }
        });
      }
      setValueGroupObject(newValueGroupObject);
      return newState;
    });
  }, [id, latestNodeHandler?.latestResult]);

  useEffect(() => {
    if (variableTable?.length === 0) return;

    const newRows = variableTable?.map(function (row) {
      const {keys, flowType, variableDbType, variableValue, path} = row;
      if (flowType === 'Export') {
        return {...row, variableValue};
      }
      if (variableDbType === 'cloud') {
        const valueFromCloudDb = valueGroupObject[keys] as ILatestNode;
        return {...row, variableValue: valueFromCloudDb?.value?.[0]?.[1]};
      } else if (variableDbType === 'local') {
        const valueFromLocalDb = valueFromDatasheet?.[path?.[1]];
        return {...row, variableValue: valueFromLocalDb};
      } else {
        return row;
      }
    });

    if (newRows) {
      setImportRows(newRows.filter((row) => row.flowType === 'Import'));
      setExportRows(newRows.filter((row) => row.flowType === 'Export'));
    }
  }, [variableTable, valueGroupObject, valueFromDatasheet]);

  useEffect(() => {
    if (!id || !variableTable || variableTable?.length === 0) return;

    const cloudDbVariable = variableTable?.filter((item) => item.variableDbType === 'cloud');
    const cloudNodeInfos = cloudDbVariable.map((item) => {
      const path = item.path;
      if (path.length > 1) {
        const [database, ...rest] = path;
        const hierarchy = rest.slice(0, -1);
        const name = rest[rest.length - 1];
        return {
          name,
          database,
          hierarchy
        };
      }
    });

    // todo latestNodeHandler hook 수정된 이후로 true 값 변경해야할 가능성 있음
    latestNodeHandler.renewSubscribe(id, cloudNodeInfos, true);
  }, [id, variableTable]);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, id, name: keyValue} = e.target;
    switch (keyValue) {
      case 'variableName': {
        // const targetRow = variableTable.find((item) => item.keys === id);
        // if (targetRow) {
        //   targetRow[keyValue] = value;
        // }
        break;
      }
      case 'flowType': {
        // setVariableTable((prev) => prev.map((item) => (item.keys === id ? {...item, [keyValue]: value} : item)));
        break;
      }
      case 'variableValue': {
        break;
      }
      case 'path': {
        break;
      }
    }
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    loader.setVariableTable((prev) => {
      const updatedTable = prev?.map((row) =>
        row.keys === name ? {...row, variableValue: value, editable: false} : row
      );
      return updatedTable;
    });
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const {value, name} = e.target as HTMLInputElement;
      loader.setVariableTable((prev) => {
        const updatedTable = prev?.map((row) =>
          row.keys === name ? {...row, variableValue: value, editable: false} : row
        );
        return updatedTable;
      });
    }
  };

  const onClick = (rowKey: string, columnKey?: string, key?: string, path?: string[]) => {
    if (rowKey === 'path') {
      if (columnKey === 'change_path') {
        onSetNodeSelectorTargetInfo(key, path);
      } else if (columnKey === 'delete_row') {
        // deleteRow(key);
      }
    } else if (rowKey === 'variableValue') {
      if (columnKey === 'custom_value') {
        loader.setVariableTable((prev) => {
          return prev?.map((row) => (row.keys === key ? {...row, editable: true, variableDbType: 'custom'} : row));
        });
      } else if (columnKey === 'current_time') {
        loader.setVariableTable((prev) => {
          return prev?.map((row) => (row.keys === key ? {...row, isCurrentTime: true, variableDbType: 'custom'} : row));
        });
      }
    }
  };

  const onSelectNodeSelector = (checked: string[], dbType: 'local' | 'cloud') => {
    const parsedData = checked.map((item) => JSON.parse(item));
    if (parsedData?.[0]) {
      const path = parsedData?.[0];

      const variableDbType: IModelRunnerVariable['variableDbType'] = dbType === 'local' ? 'local' : 'cloud';

      loader.setVariableTable((prev) => {
        return prev?.map((row) =>
          row.keys === nodeSelectorTargetInfo.key
            ? {...row, variableDbType, isCurrentTime: false, editable: false, path: path}
            : row
        );
      });
    }

    setNodeSelectorTargetInfo(null);
  };

  const onSetNodeSelectorTargetInfo = (key: string, path: string[]) => {
    setNodeSelectorTargetInfo({key, path});
  };

  const onCloseNodeSelector = () => {
    setNodeSelectorTargetInfo(null);
  };

  const h = boundaryTopRef.current?.offsetHeight - editorBodyPosition.y - widgetBottomHeight;
  const exportTableHeight = isNaN(h) ? undefined : h;

  return (
    <Container ref={boundaryRef}>
      <ModelRunnerImportTable
        height={editorBodyPosition.y}
        title="Import"
        fields={importFields}
        rows={importRows}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onClick={onClick}
        openState={openState}
      />
      <ResizeArea>
        <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
        <ModelRunnerExportTable
          height={exportTableHeight}
          title="Export"
          fields={exportFields}
          rows={exportRows}
          onChange={onChange}
          onClick={onClick}
        />
      </ResizeArea>
      {Boolean(nodeSelectorTargetInfo) && (
        <NodeSelectorRevision
          onSelect={onSelectNodeSelector}
          onClose={onCloseNodeSelector}
          //hierarchyInfos={[modelDynamicHierarchy]}
          hierarchyInfos={[dynamicHierarchy]}
          options={{selectSingleNode: true}}
          // hasCustomValue={isEditableRow && !isTimeTagRow}
          // hasTimeTag={isEditableRow && isTimeTagRow}
        />
      )}
    </Container>
  );
}

export default ModelRunnerVariableTable;
