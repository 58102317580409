import {ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div`
  height: 30px;
  width: 155px;
`;
const NumInput = styled.input`
  width: 50px;
  height: 100%;
  text-align: center;
  font-size: 15px;
  background-color: #eaeffa;
  border: 1px solid #c7cede;
  color: #475885;
  box-sizing: border-box;
  outline: none;

  &.invalid {
    border-color: ${({theme}) => theme.color.invalid};
    background-color: rgba(255, 45, 45, 0.05);
  }

  &:focus {
    background-color: #ffffff;
  }

  &:disabled {
    color: #a6a6a6;
  }
`;
const UnitSelect = styled.select`
  background-color: #eaeffa;
  border: 1px solid #c7cede;
  color: #475885;
  border-left: none;
  font-size: 15px;
  padding: 0 5px;
  outline: none;
  height: 100%;
  width: 100px;

  &:focus {
    background-color: #ffffff;
  }
`;

export type ITimeSelector = {
  value: number;
  unit: TimeUnitTypes;
  isInvalid?: boolean;
};
// 데이터 리로드에 사용하는 unit 들
export const defaultReloadUnitRange: ITimeSelector = {value: 5, unit: 'minute'};
export const defaultReloadUnitList: TimeUnitTypes[] = ['second', 'minute'];
export const defaultIntervalUnitList: TimeUnitTypes[] = ['second', 'minute', 'hour'];
export const timeUnitList = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'] as const;
export type TimeUnitTypes = (typeof timeUnitList)[number] | undefined;
export const secondsInUnit = {
  second: 1,
  minute: 60,
  hour: 3600,
  day: 86400,
  week: 604800,
  month: 2629800,
  year: 31536000
};

export const getUnitByMilliseconds = (milliseconds: number): TimeUnitTypes => {
  const millisecond = milliseconds / 1000;
  for (const key of Object.keys(secondsInUnit)) {
    if (secondsInUnit[key] === millisecond) {
      return key as TimeUnitTypes;
    }
  }
  return 'second';
};

export const getMillisecondsByUnit = (rangeUnit: ITimeSelector) => {
  return rangeUnit.value * secondsInUnit[rangeUnit.unit] * 1000;
};

type IProps = {
  value: number;
  unit: TimeUnitTypes;
  defaultUnit?: TimeUnitTypes;
  placeholder?: string;
  options?: TimeUnitTypes[];
  min?: number;
  max?: number;
  onChange?(range: ITimeSelector): void;
  disabled?: boolean;
};

function TimeSelector({
  value,
  unit,
  options = defaultReloadUnitList,
  defaultUnit = 'second',
  placeholder,
  min,
  max,
  onChange,
  disabled = false
}: IProps): ReactElement {
  const ref = useRef<HTMLInputElement>(null);
  const [invalid, setInvalid] = useState(false);
  const [num, setNum] = useState<number>(value);
  const confirmedNum = useRef<number>(num);
  const [unitType, setUnitType] = useState<TimeUnitTypes>(unit);
  const maxByUnit = max * secondsInUnit[defaultUnit];
  const minByUnit = min * secondsInUnit[defaultUnit];

  const getIsOutOfRange = (value: number, unit: TimeUnitTypes): boolean => {
    const parsedByUnit = value * secondsInUnit[unit];
    return parsedByUnit > maxByUnit || parsedByUnit < minByUnit;
  };

  useEffect(() => {
    setNum(value);
  }, [value]);

  /**
   * 기간 단위 select 변경 - 이벤트 처리부
   * @param e
   */
  const onChangeUnit = (e: ChangeEvent<HTMLSelectElement>): void => {
    const {value} = e.target;
    const u = value as TimeUnitTypes;

    setUnitType(u);

    const n = num as unknown as number;
    const outOfRange = getIsOutOfRange(n, u);
    const bool = isNaN(n) || outOfRange;
    setInvalid(bool);

    if (bool) return;

    const v = isNaN(n) ? undefined : n;
    onChange?.({value: v, unit: u, isInvalid: bool});
    confirmedNum.current = v;

    setTimeout(() => {
      ref.current.focus();
    }, 100);
  };

  /**
   * 기간 단위 숫자 입력 - 이벤트 처리부
   * @param e
   */
  const onChangeNum = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    const n = value as unknown as number;
    const v = isNaN(n) || '' ? undefined : n;
    setNum(v);

    let parsed = parseFloat(value);
    const outOfRange = getIsOutOfRange(parsed, unitType);
    const bool = isNaN(parsed) || outOfRange;
    setInvalid(bool);
  };

  const onKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      onConfirm();
    }
  };

  const onBlur = (): void => {
    if (confirmedNum.current === num) return;
    onConfirm();
  };

  const onConfirm = (): void => {
    onChange?.({value: isNaN(num) ? undefined : num, unit: unitType, isInvalid: invalid});
    confirmedNum.current = num; // onChange 로 보낸 값을 저장하여 같은 값을 계속 호출하지 않도록 한다.
  };

  return (
    <Container>
      <NumInput
        type="number"
        ref={ref}
        value={num}
        className={classNames({invalid})}
        disabled={!unitType || disabled}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onChange={onChangeNum}
      />
      <UnitSelect value={unitType} onChange={onChangeUnit} disabled={disabled}>
        {placeholder && <option value={undefined}>{placeholder}</option>}
        {options.map((unit) => (
          <option key={unit} value={unit}>
            {unit}
            {num > 1 && 's'}
          </option>
        ))}
      </UnitSelect>
    </Container>
  );
}

export default TimeSelector;
