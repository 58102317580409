import UnitToggle from 'components/pc/widgets/weather/UnitToggle';
import {Button, SelectForm, TextForm} from 'components/forms';
import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import FormRow from 'components/forms/FormRow';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import ModalFooter from 'components/common/modal/ModalFooter';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {IWeatherCfg} from 'components/pc/widgets/WeatherWidget';
import {objectsAreEqual} from 'utils/object-utils';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  display: flex;
  height: 91%;
  padding: 10px 0 0 0;
  color: #525f7f;
  flex-direction: column;
`;

const ToggleWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
  justify-content: space-between;
  flex-shrink: 0;
`;

const TextFormWrap = styled.div`
  display: flex;
  width: 26%;
`;

type IProps = {
  fontSize: string;
  color: string;
  cfg: IWeatherCfg;
  setSettingModalOpen(b: boolean): void;
  onChangeCfg(newCfg: IWeatherCfg): void;
  defaultValues: IWeatherCfg;
};

function WeatherSetting({fontSize, color, cfg, setSettingModalOpen, onChangeCfg, defaultValues}: IProps) {
  const [copy, setCopy] = useState<IWeatherCfg>(cfg);
  const {showModal, hideModal} = useContext(CommonContext);
  const disabledNoneButton = useMemo(() => objectsAreEqual<IWeatherCfg>(copy, defaultValues), [copy]);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => setSettingModalOpen(false)
  });

  const onChangeIsCelsius = () => {
    setCopy((prev) => ({...prev, isCelsius: !prev.isCelsius}));
  };

  const onChangeTimeIntervalVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCopy((prev) => ({...prev, updateIntervalVal: target.value}));
  };

  const onChangeTimeIntervalUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setCopy((prev) => ({...prev, updateIntervalUnit: Number(target.value)}));
  };

  const onCancel = () => {
    setSettingModalOpen(false);
  };

  const onConfirm = () => {
    setSettingModalOpen(false);
    onChangeCfg(copy);
  };

  const onRestore = () => {
    showModal({
      title: 'Restore Defaults',
      content: 'Do you want to restore default?',
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      isShowCancel: true,
      onConfirm: () => {
        setCopy(defaultValues);
      },
      onClose() {
        hideModal();
      }
    });
  };

  return (
    <Container className={fontSize}>
      <FormRow header="Temp unit change">
        <ToggleWrap className={`toggle-wrap ${fontSize} ${color}`}>
          <div style={{marginRight: '-20px'}}>
            <UnitToggle unitIsOn={copy?.isCelsius} onToggle={onChangeIsCelsius} />
          </div>
        </ToggleWrap>
      </FormRow>
      <FormRow header="Update interval">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={copy?.updateIntervalVal || ''}
            onChange={(e) => onChangeTimeIntervalVal(e)}
          />
        </TextFormWrap>
        <SelectForm
          name={'time-unit'}
          options={TimeUnitOptions}
          value={copy?.updateIntervalUnit}
          onChange={(e) => onChangeTimeIntervalUnit(e)}
        />
      </FormRow>
      <ModalFooter>
        <Button style={{marginRight: 'auto'}} variant="link" disabled={disabledNoneButton} onClick={onRestore}>
          Restore Defaults
        </Button>
        <Button variant="primary" width={100} onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default WeatherSetting;
