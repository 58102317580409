import {Edge, Node, NodeChange, NodePositionChange} from 'reactflow';
import {getUniqueKey} from 'utils/commons';
import {BaseSyntheticEvent, DragEvent} from 'react';
import type {XYPosition} from '@reactflow/core/dist/esm/types/utils';
import {IFile, INodeInfo} from 'api/data-types';
import {IDragWidgetActionMenu, WidgetTypes} from 'components/menu/types';
import {IWidgetNodeData} from 'components/pc/types';
import {ICommodityBasic} from 'components/pc/widgets/commodity/types';
import {spreadSheetWidgetData} from 'components/spreadsheet/const';
import {IHmbContent} from 'components/pc/widgets/hmb/type';

type IEdgeConnectParams = {
  source: string;
  target: string;
  sourceHandle: string;
  targetHandle: string;
};

export const pcFunc = {
  getZIndex: (nodes: Node[], nodeId?: string) => {
    const zIndexArray = nodes.sort((a, b) => a.zIndex - b.zIndex);
    let zIndex = 0;
    let highestZIndex = -1;
    if (zIndexArray?.length > 0) {
      highestZIndex = zIndexArray[zIndexArray?.length - 1].zIndex + 1;
    }
    if (nodeId) {
      zIndex = zIndexArray.find((node) => node.id === nodeId).zIndex;
    }
    return {zIndex, highestZIndex};
  }
};

export const pcHooks = {
  onDrop: (evt: DragEvent<HTMLDivElement>, position: XYPosition, zoom: number, nodes: Node[]) => {
    const type = evt.dataTransfer.getData('application/reactflow');
    const customData = evt.dataTransfer.getData('application/dataTransfer/widget');
    if (isNaN(1 / zoom)) {
      return;
    }
    if (customData) {
      const widgetData = JSON.parse(customData) as IDragWidgetActionMenu;
      // let data = widgetList.find((widget) => widget.id === widgetData.id);
      const id = getUniqueKey();
      const {highestZIndex} = pcFunc.getZIndex(nodes);

      return {
        id,
        type: widgetData.type,
        data: {icon: widgetData?.icon, title: widgetData?.title, mPfdFileData: widgetData?.metaPfd},
        style: widgetData?.style,
        position,
        zIndex: highestZIndex,
        selected: true
      };
    }
    if (type) {
      const {highestZIndex} = pcFunc.getZIndex(nodes);
      const height = type === 'Text' ? 50 : 100;
      const style = {width: 100 / zoom, height: height / zoom};
      return {
        id: getUniqueKey(),
        type: 'shape',
        position,
        style,
        selected: false,
        data: {
          type,
          color: '#3F8AE2'
        },
        zIndex: highestZIndex + 1
      };
    }
  },
  onNodeClick: (node: Node, nodes: Node[]) => {
    const id = node.id;
    const {zIndex, highestZIndex} = pcFunc.getZIndex(nodes, id);
    let frontZIndex: number;
    if (zIndex === highestZIndex) {
      frontZIndex = highestZIndex;
    } else {
      frontZIndex = highestZIndex + 1;
    }
    // :todo Multi select 기능 복구 (필요시)
    return [
      ...(nodes || []).map((node) =>
        node.id === id
          ? {...node, zIndex: frontZIndex, selected: true}
          : node.selected
            ? {...node, selected: false}
            : node
      )
    ];
  },
  onNodeChangeHandlingEdgesPosition: (nodeChange: NodeChange[], nodes: Node[], edges: Edge[]) => {
    if (nodeChange?.length === 0) return;
    if (
      nodeChange.length === 1 &&
      nodeChange[0].type === 'position' &&
      nodeChange[0].dragging &&
      nodeChange[0].position
    ) {
      return;
      // todo react flow edge 위치 조정 기능 추가하면 사라진 예정
      // metaPfdWidget일 경우 port handle을 widget 위치가 변경될 때마다 자동으로 조정해주는 계산 코드

      /* const nc = nodeChange[0] as NodePositionChange;
      if (nodes.find((node) => node.id === nc?.id)?.type !== 'MetaPfdWidget') {
        return;
      }
      const changedNodeId = nodeChange[0].id;
      const relatedEdges = [...(edges || [])].filter(
        (item) => item.source === changedNodeId || item.target === changedNodeId
      );
      if (relatedEdges?.length === 0) return;
      // 상대적 위치 구하기
      const newEdges = [];
      for (let i = 0; i < relatedEdges?.length; i++) {
        const {targetWrapperId, targetHandlePos, sourceWrapperId, sourceHandlePos} = pcHooks.calculatedHandlePos(
          relatedEdges[i].targetHandle,
          relatedEdges[i].sourceHandle
        );
        newEdges.push({
          ...relatedEdges[i],
          sourceHandle: sourceWrapperId + sourceHandlePos,
          targetHandle: targetWrapperId + targetHandlePos
        });
      }
      const newEdgesArray = [];
      edges.map((item) =>
        newEdges.some((ed) => ed.id === item.id)
          ? newEdgesArray.push(newEdges.find((ed) => ed.id === item.id))
          : newEdgesArray.push(item)
      );
      return newEdgesArray;*/
    }
  },
  /*onConnect: (params: IEdgeConnectParams) => {
    const {targetWrapperId, targetHandlePos, sourceWrapperId, sourceHandlePos} = pcHooks.calculatedHandlePos(
      params.targetHandle,
      params.sourceHandle
    );
    return {
      ...params,
      sourceHandle: sourceWrapperId + sourceHandlePos,
      targetHandle: targetWrapperId + targetHandlePos
    };
  },*/
  /*  calculatedHandlePos: (targetHandle: string, sourceHandle: string) => {
    const targetWrapperId = targetHandle
      .replace('_top', '')
      .replace('_right', '')
      .replace('_bottom', '')
      .replace('_left', '');

    const sourceWrapperId = sourceHandle
      .replace('_top', '')
      .replace('_right', '')
      .replace('_bottom', '')
      .replace('_left', '');

    const targetNodePortHandle = document.getElementById(targetWrapperId);
    const sourceNodePortHandle = document.getElementById(sourceWrapperId);

    let targetHandlePos: string;
    let sourceHandlePos: string;

    if (!targetNodePortHandle.getBoundingClientRect() || !targetNodePortHandle.getBoundingClientRect()) return;
    const {top: targetTop, left: targetLeft, right: targetRight} = targetNodePortHandle.getBoundingClientRect();
    const {top: sourceTop, left: sourceLeft, right: sourceRight} = sourceNodePortHandle.getBoundingClientRect();
    // target 이 soruce 보다 위에 있다
    if (targetTop < sourceTop) {
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      if (targetRight < sourceLeft) {
        targetHandlePos = '_right';
        sourceHandlePos = '_left';
      }
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      else if (targetLeft > sourceRight) {
        targetHandlePos = '_left';
        sourceHandlePos = '_right';
      }
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      else {
        targetHandlePos = '_bottom';
        sourceHandlePos = '_top';
      }
    } else if (
      /!**
       * ...............
       *!/
      targetTop > sourceTop
    ) {
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      if (targetRight < sourceLeft) {
        targetHandlePos = '_right';
        sourceHandlePos = '_left';
      }
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      else if (targetLeft > sourceRight) {
        targetHandlePos = '_left';
        sourceHandlePos = '_right';
      }
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      else {
        targetHandlePos = '_top';
        sourceHandlePos = '_bottom';
      }
    } else {
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      if (targetRight < sourceLeft) {
        targetHandlePos = '_left';
        sourceHandlePos = '_right';
      }
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |     |     A     |
      //  |___________|     |___________|
      else if (targetLeft > sourceRight) {
        targetHandlePos = '_right';
        sourceHandlePos = '_left';
      }
      //  |‾‾‾‾‾‾‾‾‾‾‾||‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     ||     B     |
      //  |___________||___________|
      else {
        targetHandlePos = '_top';
        sourceHandlePos = '_bottom';
      }
    }
    return {
      targetWrapperId,
      targetHandlePos,
      sourceWrapperId,
      sourceHandlePos
    };
  },*/
  onNodeChangeHandlingIsChangedFlag: (file: IFile, changes: NodeChange[]) => {
    const types = changes.map((change) => change.type);
    /**
     * Resizing / Dragging false => No Changes (dimensions, position)
     * After setLoad, `localInfo.initialized` is false (reset)
     * Unmount 자동적으로 remove event 발생해서 핸들링 불가
     */
    if (types.indexOf('add') !== -1) {
      return true;
    }
    if (changes[0]?.type === 'dimensions' && changes[0]?.resizing) {
      return true;
    }
    if (changes[0]?.type === 'position' && changes[0]?.dragging) {
      return true;
    }

    return false;
  },
  onNodeZIndexChangedEdgeZIndexCalc: (newNodes: Node[], node: Node, edges: Edge[]) => {
    const newZIndexOfNode = newNodes.find((item) => item.id === node.id)?.zIndex + 1 || 0;
    const relatedEdges: Edge[] = edges
      .filter((item) => item.source === node.id || item.target === node.id)
      .map((item) => ({...item, zIndex: newZIndexOfNode, style: {...item?.style, zIndex: newZIndexOfNode}}));
    return edges.map((edge) =>
      relatedEdges.find((relatedEdge) => edge?.id === relatedEdge?.id)
        ? relatedEdges.find((relatedEdge) => edge?.id === relatedEdge?.id)
        : edge
    );
  }
};

type ITitleType = {
  titleData?: string | string[] | ICommodityBasic[] | IHmbContent | INodeInfo[];
  data?: IWidgetNodeData;
};

export const getWidgetTitle = ({titleData = '', data}: ITitleType): string => {
  const isString = typeof titleData === 'string';
  const isArray = Array.isArray(titleData);

  if (!data) {
    if (typeof titleData === 'string') {
      return titleData;
    }
    return '';
  }

  let stringTitle = '';
  let isOverflow = false;
  let isZeroLength = false;
  let tagLength = '';

  if (isString) {
    stringTitle = titleData as string;
    isZeroLength = (titleData as string).length === 0;
  } else if (isArray) {
    stringTitle = (titleData as string[]).join(', ');
    isOverflow = (titleData as string[]).length > 3;
    isZeroLength = (titleData as string[]).length === 0;

    const nodesLength = (titleData as string[]).length > 4 ? 'more nodes' : 'more node';
    const seriesLength = (titleData as string[]).length > 4 ? 'more series' : 'more series';
    if (data.type === 'ProfileChartWidget') {
      tagLength = isOverflow ? ` and ${(titleData as string[]).length - 3} ${seriesLength}` : '';
    } else {
      tagLength = isOverflow ? ` and ${(titleData as string[]).length - 3} ${nodesLength}` : '';
    }
  }

  if (data.customizedTitle) {
    return `${data.title}`;
  }

  if (!data.title) {
    return '';
  }

  switch (data.type) {
    case 'StickyNoteWidget':
      return (titleData = stringTitle ? `${stringTitle.trim().split('\n')[0]}` : 'Untitled');
    case 'HmbWidget':
      if (typeof titleData === 'object' && 'selectedStreamList' in titleData) {
        const streamList = titleData as IHmbContent;
        // 3개의 배열값만 가져옴
        const hmbTitle = streamList.selectedStreamList
          .slice(0, 3)
          .map((item) => item.stream)
          .join(', ');
        const hmbTagLength =
          streamList.selectedStreamList.length > 3 ? ` and ${streamList.selectedStreamList.length - 3}` : '';
        return Object.values(streamList.selectedStreamList[0]).length === 0
          ? data.title
          : `${hmbTitle}${hmbTagLength} `;
      }
      break;
    case 'HmbRevisionWidget':
      const nodeInfos = titleData as INodeInfo[];
      if (nodeInfos && nodeInfos.length > 0) {
        const hmbTitle = nodeInfos
          .slice(0, 3)
          .map((item) => item.name)
          .join(', ');

        const nodesLength = nodeInfos.length > 4 ? 'more nodes' : 'more node';
        const hmbTagLength = nodeInfos.length > 3 ? ` and ${nodeInfos.length - 3} ${nodesLength}` : '';
        // const hmbTagLength = nodeInfos.length > 3 ? ` and ${nodeInfos.length - 3} more nodes` : '';
        return `${hmbTitle}${hmbTagLength}`;
      } else if (nodeInfos.length === 0) {
        return (titleData = 'Untitled');
      }
      break;
    case 'TimeSeriesWidget':
      if (titleData[0] !== undefined || titleData[1] !== undefined || titleData[2] !== undefined) {
        titleData = [titleData[0], titleData[1], titleData[2]].filter((key) => key !== undefined).join(', ');
      }
      return (titleData = isZeroLength ? 'Untitled' : `${titleData}${tagLength}`);

    case 'CommodityWidget':
      if (Array.isArray(titleData)) {
        if (typeof titleData[0] === 'object' && 'tag' in titleData[0]) {
          const selectedList = titleData as ICommodityBasic[];
          // 3개의 배열값만 가져옴
          const commodityTitle = selectedList
            .slice(0, 3)
            .map((item) => item.tag[1])
            .join(', ');
          return `${commodityTitle}${tagLength}`;
        } else {
          return (titleData = 'Untitled');
        }
      }
      return titleData === '' ? data.title : `${titleData}${tagLength} - ${data.title}`;
    case 'SpreadsheetWidget':
      return titleData
        ? `${titleData} - Configured Data - ${spreadSheetWidgetData.title}`
        : `${spreadSheetWidgetData.title}`;
    case 'ProfileChartWidget':
      if (titleData[0] !== undefined || titleData[1] !== undefined || titleData[2] !== undefined) {
        titleData = [titleData[0], titleData[1], titleData[2]].filter((key) => key !== undefined).join(', ');
      }
      return (titleData = isZeroLength ? 'Untitled' : `${titleData}${tagLength}`);
    case 'WeatherWidget':
    case 'ThreeLandscapeWidget':
    case 'YoutubeWidget':
    case 'WebWidget':
    case 'JmpWidget':
    case 'BlockTableWidget':
    case 'ImageWidget':
    case 'MetaPfdWidget':
    case 'DataSheetWidget':
    case 'PythonEditorWidget':
    case 'DatasheetLocalDbWidget':
    case 'ModelRunnerWidget':
      return (titleData = titleData === '' ? 'Untitled' : `${titleData}`);

    default:
      return data.title;
  }
};
