import {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {getApiHost} from 'api/function';
import {AuthContext} from 'components/auth/AuthProvider';
import {
  IModelRunnerProject,
  IModelRunnerRunConfig,
  IModelRunnerServer,
  IModelRunnerVariable,
  IoInfoData,
  IOutputList,
  IProjectInfo,
  IReceiveMessage,
  IReceiveMessageExportReadyData,
  IReceiveMessageRequestProjectConnectData,
  IReceiveMessageRequestProjectCreateData,
  IReceiveMessageRequestProjectInfoData,
  IReceiveMessageRequestProjectListData,
  IReceiveMessageRequestProjectOpenData,
  IReceiveMessageRequestProjectOutputData,
  IReceiveMessageSendProjectActionData,
  IReceiveMessageSendProjectInputData,
  IReceiveMessageSequenceEchoData,
  IReceiveProjectInfo,
  ISendMessage,
  ISeqInfo,
  IValueGroupObj,
  statusMapping
} from 'components/pc/widgets/modelRunner/types';
import {IDatasheetSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';
import useApi from 'api/useApi';
import {IApiReturnBasic, IApiServer} from 'api/data-types';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {IModelRunnerWidgetData} from 'components/pc/types';
import {getUniqueKey} from 'utils/commons';
import {modelRunnerDefaultStateValue} from 'components/pc/widgets/modelRunner/constants';
import {CommonContext} from 'components/common/CommonProvider';

export const getProjectFileName = (fileName: string) => {
  const arr = fileName?.split('\\');
  return arr?.[arr.length - 2] ?? null;
};

export type IModelRunnerLoader = {
  serverList: IModelRunnerServer[];
  server: IModelRunnerServer;
  projectList: IModelRunnerProject[];
  project: IModelRunnerProject;
  variableTable: IModelRunnerVariable[];
  importList: IModelRunnerVariable[];
  exportList: IModelRunnerVariable[];
  socketResponse: IReceiveMessage;
  logData: string;
  messages: string;
  runConfig: IModelRunnerRunConfig;
  isConnected: boolean;
  isValidProject: boolean;
  statusMessage: 'Idle' | 'Completed' | 'Running';
  seqInfo: ISeqInfo;
  error: ErrorEvent | null;

  sendMessage({type, cmd, receiver, data}: ISendMessage): void;
  requestProjectOpen(fileName: string): void;
  requestProjectConnect(fileName: string): void;
  requestProjectTerminate(fileName: string): void;
  sendProjectInput(projectName: string, fileName: string, ioName: string): Promise<void>;
  clearSocketResponse(): void;
  connectToServer(server: IModelRunnerServer): Promise<IApiReturnBasic>;
  disconnectFromServer(): void;
  getServerList(): Promise<IApiReturnBasic>;
  requestProjectList(server: IModelRunnerServer): void;
  requestProjectInfo(projectName: string, hasModelInfo?: boolean, hasSeqInfo?: boolean, hasIoInfo?: boolean): void;
  createProject(fileName: string, projectName: string): void;
  requestProjectRemove(fileName: string, owner: string): void;
  updateServer(partial: Partial<IModelRunnerServer>): void;
  updateProject(partial: Partial<IModelRunnerProject>): void;
  resetServer(): void;
  resetProject(): void;
  setMetaData(metaData: IModelRunnerWidgetData): void;
  setVariableTable: Dispatch<SetStateAction<IModelRunnerVariable[]>>;
  updateRunConfig(partial: Partial<IModelRunnerRunConfig>): void;
  runModel(fileName: string): void;
  status(modelStatus: ISeqInfo, type: 'key' | 'value'): {sts: string; cmd: string};
};

type IProps = {
  id: string;
  focusedRowDataRef: MutableRefObject<{}>;
  sourceDataBaseRef: MutableRefObject<IWidgetSubject<IDatasheetSubject>>;
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  valueGroupObjectState: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
};

const API_HOST = getApiHost();

function convertToOATime(value: number | string) {
  const date = new Date(value);
  return date.getTime() / (1000 * 60 * 60 * 24) + 25569;
}

function useModelRunnerLoader({
  id,
  focusedRowDataRef,
  sourceDataBaseRef,
  isRunState,
  valueGroupObjectState
}: IProps): IModelRunnerLoader {
  const webSocket = useRef(null);
  const [socketResponse, setSocketResponse] = useState<IReceiveMessage>();
  const {addNotice} = useContext(CommonContext);
  const {userProfile, token} = useContext(AuthContext);
  const api = useApi();

  const [logData, setLogData] = useState('');
  const [messages, setMessages] = useState('');

  const metaDataRef = useRef<IModelRunnerWidgetData>(null);
  const [serverList, setServerList] = useState<IModelRunnerServer[]>([]);
  const [server, setServer] = useState<IModelRunnerServer>(undefined);
  const [projectList, setProjectList] = useState<IModelRunnerProject[]>([]);
  const [project, setProject] = useState<IModelRunnerProject>(undefined);
  const [variableTable, setVariableTable] = useState<IModelRunnerVariable[]>([]);
  const [importList, setImportList] = useState<IModelRunnerVariable[]>([]);
  const [exportList, setExportList] = useState<IModelRunnerVariable[]>([]);
  const [runConfig, setRunConfig] = useState<IModelRunnerRunConfig>(modelRunnerDefaultStateValue.runConfigState);

  const [isRun, setIsRun] = isRunState;

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isValidProject, setIsValidProject] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<'Idle' | 'Completed' | 'Running'>(null);

  const [seqInfo, setSeqInfo] = useState<ISeqInfo>(null);

  const [error, setError] = useState<ErrorEvent>(null);

  const senderId = userProfile.username + '@' + (process.env.REACT_APP_COMPANY?.toLowerCase() || 'none') + '-' + id;

  // const seqMappingTable = (key) => {};

  const setMetaData = (metaData: IModelRunnerWidgetData): void => {
    // console.log('useModelRunnerLoader > metaData = ', metaData);
    const {modelRunnerInfo, server, project, importList, exportList, runConfig, variableTable} = metaData;
    if (modelRunnerInfo) {
      const {selectedServer, selectedProject} = modelRunnerInfo ?? {};
      setRunConfig(runConfig || modelRunnerDefaultStateValue.runConfigState);
      updateServer(selectedServer);
      updateProject(selectedProject);
      setVariableTable(variableTable);
      setImportList(variableTable.filter((row) => row.flowType === 'Import'));
      setExportList(variableTable.filter((row) => row.flowType === 'Export'));
    } else {
      setRunConfig(runConfig || modelRunnerDefaultStateValue.runConfigState);
      updateServer(server);
      updateProject(project);
      setVariableTable(variableTable);
      setImportList(importList);
      setExportList(exportList);
    }
    metaDataRef.current = metaData;
  };

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.server = server;
    }
  }, [server]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.project = project;
    }
  }, [project]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.variableTable = variableTable;
    }
  }, [variableTable]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.runConfig = runConfig;
    }
  }, [runConfig]);

  useEffect(() => {
    return () => {
      // webSocket?.current?.close();
      disconnectFromServer();
    };
  }, []);

  // project 의 현재 상태를 알기 위한 ref
  const projectRef = useRef(project);

  useEffect(() => {
    projectRef.current = project;
  }, [project]);

  const serverRef = useRef(server);

  useEffect(() => {
    serverRef.current = server;
  }, [server]);

  const variableTableRef = useRef(variableTable);

  useEffect(() => {
    variableTableRef.current = variableTable;
  }, [variableTable]);

  // auto, batch run 동작 시 현재 run 상태를 알기 위한 ref
  const isRunRef = useRef(isRun);

  useEffect(() => {
    isRunRef.current = isRun;
  }, [isRun]);

  const getWebsocketHost = (url: string, isCustomServer: boolean): string => {
    // console.log(url);
    if (isCustomServer) {
      return 'ws://localhost:9000';
    }
    if (url.startsWith('https://')) {
      // For HTTPS URLs, use WSS (WebSocket Secure)
      return url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
      // For HTTP URLs, use WS (WebSocket)
      return url.replace('http://', 'ws://');
    }
  };

  const getServerList = async (): Promise<IApiReturnBasic> => {
    return await api.get<IApiReturnBasic>('/model_manager/server_list').then((res) => {
      if (res.success) {
        setServerList(res.data as IModelRunnerServer[]);
      } else {
        const err = new ErrorEvent('GetServerListError', {message: 'Failed to get server list.\n Please try again.'});
        setError(err);
      }
      return res;
    });
  };

  const checkSocketEmulator = async () => {
    try {
      const response = await fetch('http://localhost:9000/openapi.json');
      return response.ok;
    } catch (error) {
      const err = new ErrorEvent('CustomServerConnectionError', {
        message: 'Failed to connect to the server.\n Please check your information and try again.'
      });

      setError(err);
      return false;
    }
  };

  const onOpen = (msg: Event) => {
    console.log('MMS connection opened', msg);

    addMessage('Successfully connected to Model Manager Server');
    // 저장 로직 관련 코드 (projectList 를 통해 현재 project 상태 비교를 위함)
    requestProjectList(serverRef.current);
    setLogData((prevLogData) => prevLogData + 'Connected to PMv server with ID:');
    setIsConnected(true);
  };

  const onClose = (event: CloseEvent) => {
    if (event.wasClean) {
      console.log('MMS websocket closed successfully');
      webSocket.current = null;
      setIsConnected(false);
    }
  };

  const onMessage = (msg: MessageEvent) => {
    const receive: IReceiveMessage = JSON.parse(msg.data);
    try {
      console.debug(`onMessage(msg) ${receive.Cmd} : `, receive);
      let dynamicMessage: string;
      const newLog = `Received response: ${JSON.stringify(receive, null, 2)}\n`;
      setLogData((prevLogData) => prevLogData + newLog);

      if (receive.Cmd === 'ReqProjectConnect') {
        message.onReqProjectConnect(receive.Data as IReceiveMessageRequestProjectConnectData);
      } else if (receive.Cmd === 'ReqProjectOpen') {
        message.onReqProjectOpen(receive.Data as IReceiveMessageRequestProjectOpenData);
      } else if (receive.Cmd === 'ReqProjectList') {
        dynamicMessage = message.onReqProjectList(receive.Data as IReceiveMessageRequestProjectListData);
      } else if (receive.Cmd === 'ReqProjectInfo') {
        dynamicMessage = message.onReqProjectInfo(receive.Data as IReceiveMessageRequestProjectInfoData);
      } else if (receive.Cmd === 'SendProjectInput') {
        dynamicMessage = message.onSendProjectInput(receive.Data as IReceiveMessageSendProjectInputData);
      } else if (receive.Cmd === 'SequenceEcho') {
        message.onSequenceEcho(receive.Data as IReceiveMessageSequenceEchoData);
      } else if (receive.Cmd === 'ExportReady') {
        dynamicMessage = message.onExportReady(receive);
      } else if (receive.Cmd === 'ReqProjectOutput') {
        dynamicMessage = message.onReqProjectOutput(receive.Data as IReceiveMessageRequestProjectOutputData);
      } else if (receive.Cmd === 'SendProjectAction') {
        message.onSendProjectAction(receive.Data as IReceiveMessageSendProjectActionData);
      } else if (receive.Cmd === 'ReqProjectCreate') {
        message.onReqProjectCreate(receive.Data as IReceiveMessageRequestProjectCreateData);
      }
      if (dynamicMessage) {
        addMessage(dynamicMessage);
      }
      setSocketResponse(receive);
    } catch (error) {
      console.error('수신한 메시지를 처리하는 중 오류 발생:', error);
      addNotice({type: 'error', text: error.message});
      if (receive.Cmd === 'ReqProjectInfo') {
        setIsValidProject(false);
      }
    }
  };

  // socket response stock 방지 함수
  const clearSocketResponse = () => {
    setSocketResponse(null);
  };

  const connectToServer = async (server: IModelRunnerServer) => {
    console.log('Try to connect MMS!');
    // debugger;
    const MY_TOKEN = token;
    let isCustom = Boolean(server?.custom);
    if (isCustom) {
      let connectable = await checkSocketEmulator();
      if (!connectable) {
        return;
      }
    }

    let res: IApiReturnBasic;
    let payload = {
      pmv_id: senderId,
      server_name: server.name,
      server_ip: server.ip,
      server_port: server.port
    };
    if (isCustom) {
      res = await api.post<IApiReturnBasic>(`/model_manager/connect`, payload, {host: 'http://localhost:9000'});
    } else {
      res = await api.post<IApiReturnBasic>(`/model_manager/connect`, payload);
    }

    const websocketHost = getWebsocketHost(API_HOST, isCustom);
    let websocketUrl: string = `${websocketHost}/ws/model_runner?token=${MY_TOKEN}&pmv_id=${senderId}`;
    if (isCustom) {
      const savedServer = LocalStorageManager.getItem<IApiServer>('API_SERVER');
      websocketUrl = `${websocketUrl}&server_name=${savedServer?.area}`;
    }

    if (res.success) {
      const serverObj = {...server} as IModelRunnerServer;
      setServer(serverObj);
      serverRef.current = serverObj;
      webSocket.current = new WebSocket(`${websocketUrl}`);
      webSocket.current.onopen = onOpen;
      webSocket.current.onclose = onClose;
      webSocket.current.onmessage = onMessage;
    } else {
      const err = new ErrorEvent('ServerConnectionError', {
        message: res.detail
      });
      setError(err);
      if (webSocket?.current) {
        webSocket.current?.close();
      }
    }
    return res;
  };

  const disconnectFromServer = () => {
    let dynamicMessage: string;
    if (webSocket.current) {
      webSocket.current.close();
      webSocket.current = null;
      setLogData((prevLogData) => prevLogData + 'Disconnected from server\n');
      dynamicMessage = `Disconnected from server`;
    } else {
      setLogData((prevLogData) => prevLogData + 'No active connection to disconnect\n');
      dynamicMessage = 'No active connection to disconnect';
    }
    if (dynamicMessage) {
      addMessage(dynamicMessage);
    }
  };

  const addMessage = (message: string) => {
    setMessages((prevMessages) => prevMessages + message + '\n');
  };

  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const sendMessage = ({type, cmd, receiver, data}: ISendMessage) => {
    let ID = getRandomIntInclusive(1, 4294967295);
    // console.debug('sendMessage(type, cmd, receiver, data) = ', type, cmd, receiver, data, ID);
    webSocket.current.send(
      JSON.stringify({
        Type: type,
        Cmd: cmd,
        Sender: senderId,
        Receiver: receiver,
        Data: data,
        ID: ID
      })
    );
  };

  const requestProjectOpen = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectOpen',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        RunMacro: true
      }
    });
  };

  const requestProjectConnect = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectConnect',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        PMvUser: senderId
      }
    });
  };

  const requestProjectTerminate = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectTerminate',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        RunMacro: true
      }
    });
  };

  const requestProjectOutput = (fileName: string) => {
    if (projectRef.current.fileName === fileName) {
      let ioName = projectRef.current?.modelInfo[0]?.Name;
      let ioData = projectRef.current?.ioInfo.find((io) => io.Type === 'Export' && io.Name === ioName)?.Data;
      const modifiedData = ioData.map((item) => ({
        Tag: item.Tag,
        Unit: ''
      }));
      sendMessage({
        type: 'Cmd',
        cmd: 'ReqProjectOutput',
        receiver: serverRef.current.name,
        data: {ProjectName: fileName, ExportName: ioName, OutputList: modifiedData}
      });
    }
  };

  const sendProjectInput = async (fileName: string, ioName: string) => {
    const importForLog = {};

    let variableInput = await Promise.all(
      variableTableRef.current.map(async (row) => {
        const {keys, path, flowType, variableDbType, variableName, variableValue} = row;
        if (flowType === 'Import') {
          if (variableDbType === 'cloud') {
            const valueFromCloudDb = valueGroupObjectState?.[0]?.[keys]?.value?.[0]?.[1];
            importForLog[variableName] = valueFromCloudDb;
            return {
              Tag: variableName,
              Value: valueFromCloudDb
              // todo unit 정보 받을 수 있을 때 복구
              // Unit: row.variableName === 'TimeTag' ? 'OADate' : ''
            };
          } else if (variableDbType === 'local') {
            const valueFromLocalDb = focusedRowDataRef.current?.[path?.[1]];
            importForLog[variableName] = valueFromLocalDb;
            return {
              Tag: variableName,
              Value: variableName === 'TimeTag' ? convertToOATime(valueFromLocalDb) : valueFromLocalDb,
              Unit: variableName === 'TimeTag' ? 'OADate' : ''
            };
          } else if (variableDbType === 'custom') {
            return {
              Tag: variableName,
              Value: variableName === 'TimeTag' ? convertToOATime(variableValue) : variableValue
            };
          }
        }
      })
    );

    // console.log('variableInput = ', variableInput);
    variableInput = variableInput.filter((item) => item !== undefined);

    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectInput',
      receiver: serverRef.current.name,
      data: {
        ProjectName: fileName,
        ImportName: ioName,
        InputList: {
          Validity: true,
          DataOATime: convertToOATime(Date.now()),
          Data: variableInput
        }
      }
    });
  };

  const requestProjectList = (server: IModelRunnerServer) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectList',
      receiver: server.name,
      data: {
        MasterProject: true,
        UserProject: true,
        IncludeRunning: true,
        IncludeSubMMS: false
      }
    });
  };

  const requestProjectInfo = (fileName: string, hasModelInfo = true, hasSeqInfo = true, hasIoInfo = true) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: serverRef.current.name,
      data: {ProjectName: fileName, ModelInfo: hasModelInfo, SeqInfo: hasSeqInfo, IOInfo: hasIoInfo}
    });
  };

  // reqIoInfo
  const requestProjectIoInfo = () => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo::IOInfo',
      receiver: serverRef.current.name,
      data: {}
    });
  };

  const createProject = (fileName: string, projectName: string) => {
    // console.log(fileName);
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectCreate',
      receiver: serverRef.current.name,
      data: {
        MasterProjectName: fileName,
        UserProjectName: projectName + '.mms',
        RunMacro: true
      }
    });
  };

  const requestProjectRemove = (fileName: string, owner: string): void => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectRemove',
      receiver: serverRef.current.name,
      data: {ProjectName: fileName, Owner: owner}
    });
  };

  const requestSeqInfo = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: serverRef.current.name,
      data: {ProjectName: fileName, ModelInfo: false, SeqInfo: true, IOInfo: false}
    });
  };

  const startSequence = (projectName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectAction',
      receiver: server.name + '::' + project.projectName,
      data: {ProjectName: projectName, Action: 'RunSequence', Data: {Name: 'ASDT', Parameters: [-1]}}
    });
  };

  const updateServer = (partial: Partial<IModelRunnerServer>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setServer((prev) => ({...prev, ...partial}));
  };

  const updateProject = (partial: Partial<IModelRunnerProject>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setProject((prev) => {
      const merged = {...prev, ...partial};
      projectRef.current = merged;
      return merged;
    });
  };

  const resetServer = (): void => {
    setIsConnected(false);
    setServer(undefined);
    webSocket?.current?.close();
  };

  const resetProject = (): void => {
    setProject(undefined);
  };

  const updateRunConfig = (partial: Partial<IModelRunnerRunConfig>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setRunConfig((prev) => ({...prev, ...partial}));
  };

  const runModel = (fileName: string) => {
    const firstInfo = metaDataRef.current.project?.seqInfo?.[0];
    //todo firstInfo 의 seqInfo 가 업데이트 안됨. 이전 상태임
    // console.log('firstInfo = ', firstInfo);
    if (!firstInfo) return;
    const {Status, Name, Command} = firstInfo;
    if (Command === 'WAIT_IMPORT' || Status === 'Completed') {
      // 현재는 사용하지 않지만 복구 가능성 있음
      /* startSequence(fileName);*/
      sendProjectInput(fileName, Name);
      updateProject({isRun: true});
    }
  };

  // model 의 status, command 값을 보내줌. 용도에 따라 statusMapping 의 value 혹은 key 값을 사용
  const status = (modelStatus: ISeqInfo, type: 'key' | 'value'): {sts: string; cmd: string} => {
    if (!modelStatus) {
      return {sts: '', cmd: ''};
    }

    const {Status: sts, Command: cmd} = modelStatus;

    if (type === 'key') {
      return {
        sts: sts || '',
        cmd: cmd || ''
      };
    }

    if (type === 'value') {
      return {
        sts: statusMapping[sts] || '',
        cmd: statusMapping[cmd] || ''
      };
    }
  };

  //onMessage 의 호출용 함수
  const message = {
    onReqProjectConnect: (receiveData: IReceiveMessageRequestProjectConnectData): void => {
      const data = receiveData;
      projectRef.current.fileName = data.ProjectName;
      setProject((prev) => ({...prev, fileName: data.ProjectName}));
    },
    onReqProjectOpen: (receiveData: IReceiveMessageRequestProjectOpenData): void => {
      updateProject({fileName: receiveData.ProjectName});
    },
    onReqProjectList: (receiveData: IReceiveMessageRequestProjectListData): string => {
      const {ProjectList, Notify} = receiveData;
      const refined: IModelRunnerProject[] = ProjectList.map((project: IReceiveProjectInfo) => {
        return {
          projectName: project.ProjectName,
          alias: getProjectFileName(project.FileName),
          server: project.Server,
          fileName: project.FileName,
          owner: project.Owner,
          pmvUser: project.PMvUser,
          isPublic: project.IsPublic,
          isEditable: project.IsEditable,
          isUserProject: project.IsUserProject,
          isRunning: project.IsRunning,
          description: project.Description,
          modelInfo: [],
          seqInfo: [],
          ioInfo: [],
          model_list: [],
          isRun: false,
          isBatch: false
        };
      });
      setProjectList(refined);

      return Notify.Message;
    },
    onReqProjectInfo: (receiveData: IReceiveMessageRequestProjectInfoData): string => {
      const {ModelInfo, IOInfo, SeqInfo, ProjectName, Notify} = receiveData;
      // reqProjectInfo error 방어 코드
      if (Notify.ErrorCode === -1) {
        console.warn(Notify.Message);
        return;
      }

      setIsValidProject(true);

      const merged: Partial<IModelRunnerProject> = {};
      if (ModelInfo?.length > 0) {
        merged.modelInfo = ModelInfo;
      }
      if (IOInfo?.length > 0) {
        merged.ioInfo = IOInfo;

        const isIoLoad =
          metaDataRef?.current?.project?.ioInfo === undefined || metaDataRef?.current?.project?.ioInfo?.length === 0;

        if (isIoLoad) {
          const refined = IOInfo.flatMap((ioInfo) =>
            ioInfo.Data.map((ioInfoData) => ({
              variableName: ioInfoData.Tag,
              flowType: ioInfo.Type,
              variableValue: ioInfoData.Value,
              editable: false,
              isCurrentTime: false,
              path: [],
              keys: getUniqueKey()
            }))
          );
          setVariableTable(refined);
        }
      }
      if (SeqInfo?.length > 0) {
        merged.seqInfo = SeqInfo;
        const [firstSeq] = merged.seqInfo;
        setSeqInfo(firstSeq);
        setStatusMessage(firstSeq.Status);

        //todo setIsRun state 이전 상태
        if (firstSeq.Command === 'WAIT_IMPORT' || firstSeq.Status === 'Completed') {
          const conf = metaDataRef.current.runConfig;
          // console.log(conf);
          if (isRunRef.current) {
            if (conf.autoRun) {
              if (conf?.intervalValue > 0) {
                setTimeout(() => runModel(receiveData.ProjectName), conf?.intervalValue * conf?.intervalUnit);
              }
            } else if (conf.batchRun) {
              // if (conf.batchRun) {
              // localDatabase?.ref?.current?.next().then((success) => {
              //   if (success) {
              //     setTimeout(() => runCode(), 100);
              //   } else {
              //     setIsRun(false);
              //   }
              // });
              // }
            } else {
              setIsRun(false);
            }
          } else {
            setIsRun(false);
          }
        }
      }
      // seq만 요청했을 경우 처리 로직
      if (SeqInfo?.length > 0 && ModelInfo?.length === 0 && IOInfo?.length === 0) {
        // debugger;
        // merged.seqInfo = SeqInfo;
        const [firstSeq] = merged.seqInfo;

        setSeqInfo(firstSeq);
        setStatusMessage(firstSeq.Status);
        // run 조건에 사용될 seqInfo 업데이트
        updateProject({seqInfo: merged.seqInfo});
      } else {
        updateProject(merged);
        return `Project ${ProjectName}: Information has been updated.`;
      }
      return;
    },
    onSendProjectInput: (receiveData: IReceiveMessageSendProjectInputData): string => {
      // // status 변환 update Seq요청
      requestSeqInfo(receiveData.ProjectName);
      return `Project ${receiveData.ProjectName}: Data Successfully imported.`;
    },
    onSequenceEcho: (receiveData: IReceiveMessageSequenceEchoData): void => {
      const {Echo, Command, ProjectName} = receiveData;
      if (Echo === 'BeforeRun') {
        // requestSeqInfo(ProjectName);
      } else if (Echo === 'AfterRun') {
        if (Command === 'SAVE_MODEL') {
          requestSeqInfo(ProjectName);
          // todo projectOutput 과 save model 이 동시에 요청되는 경우가 있음. exportReady 시 requestProjectOutput 을 요청할 때 save model 과 겹치면 model이 망가지는 문제 발생
          // requestProjectOutput(ProjectName);
        }
      }
    },
    onExportReady: (receive: IReceiveMessage): string => {
      const {ProjectName} = (receive?.Data as IReceiveMessageExportReadyData) ?? {};
      const [ioName] = receive?.Sender.split('::');
      // status 변환 update Seq요청
      // requestSeqInfo(ProjectName);
      requestProjectOutput(ProjectName);
      return `Project ${ProjectName}: Export for "${ioName}" ready.`;
    },
    onReqProjectOutput: (receiveData: IReceiveMessageRequestProjectOutputData): string => {
      const {ProjectName, Notify, OutputList} = receiveData;
      // status 변환 update Seq요청
      requestSeqInfo(ProjectName);
      if (Notify.ErrorCode === 0) {
        const convertOATimeToDate = (oaTime: number) => {
          const OADateEpoch = new Date(Date.UTC(1899, 11, 30));
          const msPerDay = 24 * 60 * 60 * 1000;
          const dateInMs = OADateEpoch.getTime() + oaTime * msPerDay;
          const date = new Date(dateInMs);

          // Format the date as 'YYYY-MM-DD HH:MM:SS'
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const newRow = {} as IOutputList;
        newRow.DataOATime = convertOATimeToDate(OutputList[0].DataOATime);

        OutputList[0].Data.forEach((item: IoInfoData) => {
          if (item.Tag !== 'TimeTag') {
            newRow[item.Tag] = item.Value;
          } else {
            // time tag 값이 없을 경우 빈 문자열로 표시
            newRow[item.Tag] = item.Value !== 'NaN' ? convertOATimeToDate(item.Value as number) : '';
          }
        });

        // export row node db 저장
        if (variableTableRef.current) {
          const updatedVariableTable = variableTableRef.current.map((variable) => {
            if (variable.flowType === 'Export' && newRow[variable.variableName] !== undefined) {
              return {...variable, variableValue: newRow[variable.variableName]};
            }
            return variable;
          });

          setVariableTable(updatedVariableTable);

          const node_data_list = updatedVariableTable
            ?.filter((variable) => variable.variableDbType === 'cloud')
            .filter((variable) => variable.flowType === 'Export')
            .map((variable) => {
              const [db, ...path] = variable.path;
              return {
                database: db,
                path: path,
                records: [[Date.now() / 1000, variable.variableValue]]
              };
            });

          if (node_data_list.length > 0) {
            api.post('/db_manage/store_node_data_list', {node_data_list: node_data_list});
          }
        }

        sourceDataBaseRef?.current?.ref?.current?.getOutflowResult({outflowResult: newRow});
      }
      return `Project ${ProjectName}: Data has been successfully exported.`;
    },
    onSendProjectAction: (receiveData: IReceiveMessageSendProjectActionData): void => {
      const {ProjectName, Action, Notify} = receiveData;
      if (Action === 'RunSequence') {
        if (Notify?.Message === 'Sequence ASDT: SeqStart') {
          if (projectRef.current?.projectName === ProjectName) {
            // sendProjectInput(ProjectName, projectRef.current?.seqInfo[0]?.Name);
          }
        }
        // todo echo test
        // requestSeqInfo(ProjectName);
      }
    },
    onReqProjectCreate: (receiveData: IReceiveMessageRequestProjectCreateData): void => {
      const {Notify} = receiveData;
      if (Notify.ErrorCode === 0) {
        // todo error 처리 필요 부분
        // alert('Successfully Created');
        // requestProjectList();
      }
    }
  };

  return {
    serverList,
    server,
    projectList,
    project,
    variableTable,
    importList,
    exportList,
    socketResponse,
    logData,
    messages,
    runConfig,
    isConnected,
    isValidProject,
    statusMessage,
    seqInfo,
    error,

    updateServer,
    updateProject,
    resetServer,
    resetProject,
    requestProjectList,
    requestProjectInfo,
    createProject,
    requestProjectRemove,
    sendMessage,
    requestProjectOpen,
    requestProjectConnect,
    requestProjectTerminate,
    sendProjectInput,
    clearSocketResponse,
    connectToServer,
    disconnectFromServer,
    getServerList,
    setMetaData,
    setVariableTable,
    updateRunConfig,
    runModel,
    status
  };
}

export default useModelRunnerLoader;
