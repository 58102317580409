import {memo, MouseEvent, PropsWithChildren, ReactElement, useContext, useState} from 'react';
import classNames from 'classnames';
import {NodeResizeControl, NodeResizer} from 'reactflow';
import {toKebabCase} from 'utils/commons';
import {WidgetTypes} from 'components/menu/types';
import styled from 'styled-components';
import MetaPfdWidgetHandle from 'components/pc/widgets/parts/MetaPfdWidgetHandle';
import {IWidgetNodeData} from 'components/pc/types';
import {ResizeDragEvent, ResizeParams} from '@reactflow/node-resizer/dist/esm/types';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import WidgetHandle from 'components/pc/widgets/parts/WidgetHandle';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: transparent;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #8a9496;
  box-sizing: border-box;
  position: relative;

  &.frozen {
    pointer-events: none;
    cursor: default;
  }
  &.iconMode {
    .widget-body,
    .widget-header {
      display: none;
    }
  }
`;
const ResizeIcon = styled.div`
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  right: 1px;
  bottom: 1px;
  border-radius: 0 0 10px 0;

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const controlStyle = {
  background: 'transparent',
  border: 'none',
  zIndex: 100
};

type IProps = PropsWithChildren & {
  type?: WidgetTypes;
  selected?: boolean;
  width?: number;
  height?: number;
  id?: string;
  data?: IWidgetNodeData;
  iconMode?: boolean;
  onResizingWidgetStart?(event: ResizeDragEvent, params: ResizeParams, bool: boolean): void;
  onResizingWidgetEnd?(event: ResizeDragEvent, params: ResizeParams, bool: boolean): void;
  onMouseEnter?(e: MouseEvent<HTMLDivElement>): void;
  onMouseLeave?(e: MouseEvent<HTMLDivElement>): void;
};

function WidgetContainer({
  children,
  selected,
  type,
  data,
  id,
  iconMode,
  onResizingWidgetStart,
  onResizingWidgetEnd,
  onMouseEnter,
  onMouseLeave
}: IProps): ReactElement {
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;

  const [resizing, setResizing] = useState(false);

  const onResizingStart = (event: ResizeDragEvent, params: ResizeParams) => {
    setResizing(true);
    onResizingWidgetStart?.(event, params, true);
  };

  const onResizeEnd = (event: ResizeDragEvent, params: ResizeParams) => {
    setResizing(false);
    onResizingWidgetEnd?.(event, params, false);
  };

  return (
    <>
      <Container
        className={classNames('widget-container', toKebabCase(type), {frozen: isFreeze}, {iconMode})}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </Container>
      {!data.docked && !iconMode && (
        <>
          <NodeResizer
            color="#ff0071"
            isVisible={selected}
            keepAspectRatio={type === 'MetaPfdWidget'}
            onResizeStart={onResizingStart}
            onResizeEnd={onResizeEnd}
          />
          <NodeResizeControl
            style={controlStyle}
            minWidth={data.minWidth}
            minHeight={data.minHeight}
            keepAspectRatio={type === 'MetaPfdWidget'}
            onResizeStart={onResizingStart}
            onResizeEnd={onResizeEnd}
          >
            <ResizeIcon>
              <svg width="64px" height="64px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path d="M10 20L20 20L20 10" stroke="#CCCCCC" strokeWidth="2"></path>
                  <path d="M12 17L17 17L17 12" stroke="#CCCCCC" strokeWidth="2"></path>
                </g>
              </svg>
            </ResizeIcon>
          </NodeResizeControl>
        </>
      )}

      {type === 'MetaPfdWidget' && !resizing && (
        <MetaPfdWidgetHandle zIndex={data?.zIndex + 10 || 9999} id={id} data={data} resizing={resizing} />
      )}
      <WidgetHandle type={type} data={data} id={id} />
    </>
  );
}

export default memo(WidgetContainer);
